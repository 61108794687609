<script setup lang="ts">
import type {
  AttributeMeta,
  IntegerAttribute,
  SetIntegerAttributeOnEventCommand,
  UnsetAttributeCommand
} from '@/generated/graphql'
import { inject, reactive, ref, watch } from 'vue'
import { v4 } from 'uuid'
import TextField from '@/components/input/TextField.vue'
import type { AttributeConfiguration } from '@/components/input/AttributeConfigurationField.vue'
import { keyEventDuplication } from '@/components/events/shared'

const props = defineProps<{
  eventId: string
  meta: AttributeMeta
  seed?: IntegerAttribute
}>()
const emit = defineEmits<{
  command: [value: SetIntegerAttributeOnEventCommand | UnsetAttributeCommand]
}>()

type MutationModel = {
  id?: string
  attributeId: string
  eventId: string
  integer?: number
}
const initialState: MutationModel = {
  id: props.seed?.id || v4(),
  attributeId: props.meta.id,
  eventId: props.eventId,
  integer: props.seed?.integer
}
const mutationModel = reactive<MutationModel>({ ...initialState })
const integer = ref<string>(props.seed?.integer)
function onBlur() {
  const v = integer.value
  if (v === undefined) {
    mutationModel.integer = undefined
    integer.value = undefined
    return
  }

  const maybeInt = parseInt(v!)
  if (isNaN(maybeInt)) {
    mutationModel.integer = undefined
    integer.value = undefined
    return
  }

  mutationModel.integer = maybeInt
}
const unsetCommand: UnsetAttributeCommand = {
  eventId: props.eventId,
  attributeId: initialState.id!
}
function isComplete(model: MutationModel) {
  return model.integer !== undefined
}
const isDuplication = inject(keyEventDuplication)!
watch(
  mutationModel,
  () => emit('command', isComplete(mutationModel) ? mutationModel : unsetCommand),
  { immediate: isDuplication.value }
)
</script>

<template>
  <text-field :label="props.meta.name" v-model="integer" @blur="onBlur()" />
</template>

<style scoped lang="scss"></style>
