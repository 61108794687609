<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  disableReason?: string
}>()
defineEmits<{
  click: []
}>()

const disabled = computed(() => !!props.disableReason)
</script>

<template>
  <v-icon
    icon="delete"
    :style="disabled ? 'cursor: not-allowed' : undefined"
    :color="disabled ? 'grey' : undefined"
    :title="disabled ? props.disableReason : 'Delete item'"
    @click="disabled ? undefined : $emit('click')"
  />
</template>

<style scoped lang="scss"></style>
