<script setup lang="ts">
import { inject, nextTick, ref, watch } from 'vue'
import { keyOrganizationId, keyProjectId } from '@/app'
import { useMutation } from '@vue/apollo-composable'
import {
  IntegrationGoogleSheetsImportAnalysis,
  IntegrationGoogleSheetsImportAnalysisEvent
} from '@/generated/graphql'
import gql from 'graphql-tag'
import GoogleSheetsOauthRequester from '@/components/integrations/google-sheets/GoogleSheetsOauthRequester.vue'
import TextField from '@/components/input/TextField.vue'
import type { FetchResult } from '@apollo/client'

const orgId = inject(keyOrganizationId)!
const projectId = inject(keyProjectId)!
const url = ref('')
const approvedChanges = ref<string[]>([])
const analyzeMutation = useMutation(
  gql`
    mutation googleSheetsAnalyzeImport(
      $orgId: ID!
      $projectId: ID!
      $url: String!
      $approvedChanges: [ID!]!
    ) {
      organization(id: $orgId) {
        project(id: $projectId) {
          integrations {
            googleSheets {
              importSheet(url: $url, approvedChanges: $approvedChanges) {
                id
                warnings
                errors
                events {
                  id
                  event {
                    id
                    name
                  }
                  changes {
                    id
                    field
                    oldValue
                    newValue
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  () => ({
    variables: {
      orgId: orgId.value,
      projectId: projectId.value,
      url: url.value,
      approvedChanges: approvedChanges.value
    }
  })
)

const analysis = ref<IntegrationGoogleSheetsImportAnalysis | undefined>()

function approveAll(event: IntegrationGoogleSheetsImportAnalysisEvent) {
  event.changes.forEach((change) => {
    if (!approvedChanges.value.includes(change.id)) {
      approvedChanges.value.push(change.id)
    }
  })
}

function unapproveAll(event: IntegrationGoogleSheetsImportAnalysisEvent) {
  const ids = event.changes.map((c) => c.id)
  approvedChanges.value = approvedChanges.value.filter((a) => !ids.includes(a))
}

function isChangeApproved(id: string) {
  return approvedChanges.value.includes(id)
}

function toggleChangeApproval(id: string) {
  if (isChangeApproved(id)) {
    approvedChanges.value = approvedChanges.value.filter((a) => a !== id)
  } else {
    approvedChanges.value.push(id)
  }
}

const oauthRefetch = ref<number>()
const mutationSuccess = ref<boolean>()
const changesImported = ref<boolean>()
const googleSheetsAuthorized = ref(false)
const eventTab = ref()

async function startImport() {
  googleSheetsAuthorized.value = false
  oauthRefetch.value = Math.random()
}
watch([googleSheetsAuthorized, oauthRefetch], (v: boolean) => {
  analysis.value = undefined
  mutationSuccess.value = undefined
  changesImported.value = undefined
  if (!v) {
    return
  }

  analyzeMutation
    .mutate()
    .then((result: FetchResult) => {
      mutationSuccess.value = true
      analysis.value = result.data.organization.project.integrations.googleSheets.importSheet
      nextTick(() => (eventTab.value = analysis.value?.events[0].id))
      if (approvedChanges.value.length > 0) {
        changesImported.value = mutationSuccess.value
        approvedChanges.value = []
      }
    })
    .catch((reason: unknown) => {
      mutationSuccess.value = false
      console.log("Could not import Google Sheet", reason)
      throw new Error(`"Could not import Google Sheet: ${reason}`)
    })
})
</script>

<template>
  <h1>Google Sheets import</h1>
  <p style="margin-bottom: 1em">
    This integration allows you to import changes in the Google Sheet that you exported earlier with
    this application through the calender. Just point us towards the url of the Google Sheet, we'll
    analyze the changes and ask you which changes you wish to import. Because of the complexity, not
    all types of fields can be imported
  </p>

  <google-sheets-oauth-requester
    v-if="oauthRefetch"
    :refetch="oauthRefetch"
    @authorized="googleSheetsAuthorized = true"
  />

  <text-field
    required
    label="Google Sheet url"
    v-model="url"
    :append-icon="
      mutationSuccess ? 'check_circle' : mutationSuccess === false ? 'error' : undefined
    "
  >
    <template #append>
      <v-btn color="primary" :loading="analyzeMutation.loading.value" @click="startImport()">
        {{ approvedChanges.length === 0 ? 'Analyze' : 'Import selected changes' }}
      </v-btn>
    </template>
  </text-field>

  <v-alert type="error" v-if="changesImported === false">
    Something went while importing the changes
  </v-alert>
  <v-alert type="success" v-if="changesImported"> All selected changes have been imported </v-alert>

  <div v-if="analysis">
    <h2>Analysis</h2>

    <v-alert type="warning" v-if="analysis.warnings.length > 0" title="Warnings">
      <ul>
        <li v-for="warning in analysis.warnings" :key="warning">
          {{ warning }}
        </li>
      </ul>
    </v-alert>

    <v-alert type="error" v-if="analysis.errors.length > 0" title="Errors">
      <ul>
        <li v-for="error in analysis.errors" :key="error">{{ error }}</li>
      </ul>
    </v-alert>

    <h3>Changes</h3>

    <v-alert type="info" v-if="analysis?.events.length == 0">No changes</v-alert>

    <v-card v-if="analysis?.events.length > 0">
      <v-tabs v-model="eventTab">
        <v-tab v-for="e in analysis?.events" :key="e.id" :value="e.id">{{ e.event.name }}</v-tab>
      </v-tabs>

      <v-card-text>
        <v-window v-model="eventTab">
          <v-window-item v-for="e in analysis?.events" :key="e.id" :value="e.id">
            <v-table class="change-table" density="compact" fixed-header fixed-footer>
              <thead>
                <tr>
                  <th style="width: 100px">
                    <v-icon @click="approveAll(e)" icon="check_box" />
                    <v-icon @click="unapproveAll(e)" icon="check_box_outline_blank" />
                  </th>
                  <th style="width: 300px">Field</th>
                  <th>Old value</th>
                  <th>New value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="change in e.changes" :key="change.id">
                  <td>
                    <v-checkbox
                      :model-value="isChangeApproved(change.id)"
                      @update:modelValue="toggleChangeApproval(change.id)"
                      hide-details
                      density="compact"
                    />
                  </td>
                  <td>{{ change.field.trim() }}</td>
                  <td>{{ change.oldValue?.trim() }}</td>
                  <td>{{ change.newValue?.trim() }}</td>
                </tr>
              </tbody>
            </v-table>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
  </div>
</template>

<style lang="scss">
.change-table {
  tr,
  td {
    height: 25px !important;
    padding: 0;
    margin: 0;
  }
}
</style>
