<script setup lang="ts" generic="MM">
import FormField from '@/components/input/FormField.vue'
import { Validation, ValidationArgs } from '@vuelidate/core'

const props = defineProps<{
  modelValue?: string
  label: string
  required?: boolean
  validation?: Validation<ValidationArgs, MM>
}>()
const emit = defineEmits<{
  'update:modelValue': [value: string]
  blur: []
}>()

function onBlur() {
  props.validation?.$touch()
  emit('blur')
}
</script>

<template>
  <form-field :label="props.label" :validation="props.validation" :required="props.required">
    <v-text-field
      :model-value="props.modelValue"
      @update:modelValue="(val) => $emit('update:modelValue', val)"
      @blur="onBlur()"
      hide-details
    >
      <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </v-text-field>
  </form-field>
</template>
