<script setup lang="ts" generic="MM">

const props = defineProps<{
  modelValue: boolean
  title: string
  content: string
}>()
const emit = defineEmits<{
  'update:modelValue': [value: boolean]
  ok: []
}>()
function ok() {
  emit('ok')
  emit('update:modelValue', false)
}

</script>

<template>
  <v-dialog
    max-width="650"
    :model-value="props.modelValue"
    @update:modelValue="(v) => $emit('update:modelValue', v)"
    @keydown.ctrl.enter.stop="ok()"
    @keydown.meta.enter.stop="ok()"
  >
    <v-card>
      <v-card-title>{{props.title}}</v-card-title>
      <v-card-text>
        {{props.content}}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="$emit('update:modelValue', false)">Cancel</v-btn>
        <v-btn @click="ok()" color="primary">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
