<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { keyOrganizationId, keyProjectId } from '@/app'
import { computed, inject, reactive, watch } from 'vue'
import type {
  AddressAttribute,
  AttributeMeta,
  AttributeUnion,
  ChoiceAttribute,
  FileAttribute,
  FlightAttribute,
  IntegerAttribute,
  LinkAttribute,
  NocturnalAttribute,
  ResourceAttribute,
  SetAddressAttributeOnEventCommand,
  SetChoiceAttributesOnEventCommand,
  SetFileAttributesOnEventCommand,
  SetFlightAttributeOnEventCommand,
  SetIntegerAttributeOnEventCommand,
  SetLinkAttributesOnEventCommand,
  SetNocturnalAttributeOnEventCommand,
  SetResourceAttributesOnEventCommand,
  SetTextAttributeOnEventCommand,
  SetToggleAttributeOnEventCommand,
  TextAttribute,
  ToggleAttribute,
  UnsetAttributeCommand
} from '@/generated/graphql'
import { AttributeType } from '@/generated/graphql'
import InputTextAttribute from '@/components/events/attributes/InputTextAttribute.vue'
import InputAddressAttribute from '@/components/events/attributes/InputAddressAttribute.vue'
import InputChoiceAttribute from '@/components/events/attributes/InputChoiceAttribute.vue'
import InputFileAttribute from '@/components/events/attributes/InputFileAttribute.vue'
import InputIntegerAttribute from '@/components/events/attributes/InputIntegerAttribute.vue'
import InputLinkAttribute from '@/components/events/attributes/InputLinkAttribute.vue'
import InputToggleAttribute from '@/components/events/attributes/InputToggleAttribute.vue'
import InputResourceAttribute from '@/components/events/attributes/InputResourceAttribute.vue'
import InputFlightAttribute from '@/components/events/attributes/InputFlightAttribute.vue'
import { end } from '@popperjs/core'

export type SetCommand =
  | SetTextAttributeOnEventCommand
  | SetIntegerAttributeOnEventCommand
  | SetToggleAttributeOnEventCommand
  | SetChoiceAttributesOnEventCommand
  | SetResourceAttributesOnEventCommand
  | SetFlightAttributeOnEventCommand
  | SetNocturnalAttributeOnEventCommand
  | SetAddressAttributeOnEventCommand
  | SetLinkAttributesOnEventCommand
  | SetFileAttributesOnEventCommand
  | UnsetAttributeCommand
export type AttributeDataMap = Record<AttributeType, Record<string, SetCommand>>
export type AttributeDataList = Record<AttributeType, SetCommand[]>

const props = defineProps<{
  eventId: string
  eventTypeId?: string
  seeds?: AttributeUnion[]
}>()
const emit = defineEmits<{
  setAttributes: [value: AttributeDataList]
  unsetAttributes: [value: UnsetAttributeCommand[]]
  setStartAndEnd: [start: string, startTimezone: string, end: string, endTimezone: string]
}>()

const orgId = inject(keyOrganizationId)!
const projectId = inject(keyProjectId)!
const eventTypeFetchQuery = useQuery(
  gql`
    query getEventTypeForAttributeInput($orgId: ID!, $projectId: ID!, $eventTypeId: ID!) {
      organization(id: $orgId) {
        project(id: $projectId) {
          eventType(id: $eventTypeId) {
            id
            attributeMetas {
              id
              name
              type
              position
              configuration
            }
          }
        }
      }
    }
  `,
  () => ({
    orgId: orgId.value,
    projectId: projectId.value,
    eventTypeId: props.eventTypeId
  }),
  () => ({
    enabled: !!props.eventTypeId
  })
)
const metas = computed<AttributeMeta[]>(() => {
  const metas =
    eventTypeFetchQuery.result.value?.organization.project.eventType.attributeMetas || []
  return JSON.parse(JSON.stringify(metas)).sort(
    (a: AttributeMeta, b: AttributeMeta) => a.position - b.position
  )
})

const initialState: AttributeDataMap = {
  ADDRESS: {},
  CHOICE: {},
  FILE: {},
  FLIGHT: {},
  INTEGER: {},
  LINK: {},
  NOCTURNAL: {},
  RESOURCE: {},
  TOGGLE: {},
  TEXT: {}
}
const dataMap = reactive<AttributeDataMap>({ ...initialState })
watch(
  metas,
  (value: AttributeMeta[]) => {
    Object.keys(dataMap).forEach((typeKey) => {
      Object.keys(dataMap[typeKey]).forEach((dataKey) => {
        delete dataMap[typeKey][dataKey]
      })
    })
  },
  { immediate: true }
)
watch(
  dataMap,
  (v: AttributeDataMap) => {
    let setList: AttributeDataList = {
      ADDRESS: [],
      CHOICE: [],
      FILE: [],
      FLIGHT: [],
      INTEGER: [],
      LINK: [],
      NOCTURNAL: [],
      RESOURCE: [],
      TOGGLE: [],
      TEXT: []
    }
    let unsetList: UnsetAttributeCommand[] = []

    Object.keys(v).forEach((stringTypeKey: string) => {
      const typeKey = stringTypeKey as AttributeType
      Object.keys(v[typeKey]).forEach((attrId: string) => {
        const command = v[typeKey][attrId]
        if (Object.keys(command).length === 2) {
          unsetList.push(command)
        } else {
          setList[typeKey].push(command)
        }
      })
    })

    emit('setAttributes', setList)
    emit('unsetAttributes', unsetList)
  },
  { immediate: true }
)
function setCommand(meta: AttributeMeta, command: SetCommand) {
  dataMap[meta.type][meta.id] = command
}

type SeedMap = {
  [index: string]: AttributeUnion
}
const seeds = computed<SeedMap>(() => {
  let map: SeedMap = {}
  props.seeds?.forEach((s: AttributeUnion) => {
    map[s.meta.id] = s
  })
  return map
})

//lodash.equals voor binnenkomende en uitgaande data
</script>

<template>
  <template v-if="!!props.eventTypeId">
    <template v-for="meta in metas" :key="meta.id">
      <input-address-attribute
        v-if="meta.type === 'ADDRESS'"
        :meta="meta"
        :event-id="props.eventId"
        :seed="seeds[meta.id] as AddressAttribute"
        @command="(v) => setCommand(meta, v)"
      />
      <input-choice-attribute
        v-if="meta.type === 'CHOICE'"
        :meta="meta"
        :event-id="props.eventId"
        :seed="seeds[meta.id] as ChoiceAttribute"
        @command="(v) => setCommand(meta, v)"
      />
      <input-file-attribute
        v-if="meta.type === 'FILE'"
        :meta="meta"
        :event-id="props.eventId"
        :seed="seeds[meta.id] as FileAttribute"
        @command="(v) => setCommand(meta, v)"
      />
      <input-flight-attribute
        v-if="meta.type === 'FLIGHT'"
        :meta="meta"
        :event-id="props.eventId"
        :seed="seeds[meta.id] as FlightAttribute"
        @command="(v) => setCommand(meta, v)"
        @set-start-and-end="
          (start, startTimezone, end, endTimezone) => {
            emit('setStartAndEnd', start, startTimezone, end, endTimezone)
          }
        "
      />
      <input-integer-attribute
        v-if="meta.type === 'INTEGER'"
        :meta="meta"
        :event-id="props.eventId"
        :seed="seeds[meta.id] as IntegerAttribute"
        @command="(v) => setCommand(meta, v)"
      />
      <input-link-attribute
        v-if="meta.type === 'LINK'"
        :meta="meta"
        :event-id="props.eventId"
        :seed="seeds[meta.id] as LinkAttribute"
        @command="(v) => setCommand(meta, v)"
      />
      <input-toggle-attribute
        v-if="meta.type === 'NOCTURNAL'"
        :meta="meta"
        :event-id="props.eventId"
        :seed="seeds[meta.id] as NocturnalAttribute"
        @command="(v) => setCommand(meta, v)"
      />
      <input-toggle-attribute
        v-if="meta.type === 'TOGGLE'"
        :meta="meta"
        :event-id="props.eventId"
        :seed="seeds[meta.id] as ToggleAttribute"
        @command="(v) => setCommand(meta, v)"
      />
      <input-text-attribute
        v-if="meta.type === 'TEXT'"
        :meta="meta"
        :event-id="props.eventId"
        :seed="seeds[meta.id] as TextAttribute"
        @command="(v) => setCommand(meta, v)"
      />
      <input-resource-attribute
        v-if="meta.type === 'RESOURCE'"
        :meta="meta"
        :event-id="props.eventId"
        :seed="seeds[meta.id] as ResourceAttribute"
        @command="(v) => setCommand(meta, v)"
      />
    </template>
  </template>
</template>

<style scoped lang="scss"></style>
