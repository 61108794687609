import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  DefaultOptions,
  InMemoryCache,
  NextLink,
  Operation
} from '@apollo/client/core'
import { Auth0VueClient, useAuth0 } from '@auth0/auth0-vue'
import result from './generated/graphql-fragments'

let auth0: Auth0VueClient | undefined = undefined
let loadingPromise: Promise<void> | undefined = undefined

function createLoadingPromise() {
    loadingPromise = new Promise<void>((resolve, reject) => {
        const almostAuth0 = useAuth0()
        let intervalId: number | undefined = undefined
        intervalId = window.setInterval(() => {
            if (!!almostAuth0 && !almostAuth0.isLoading.value) {
                auth0 = almostAuth0
                window.clearInterval(intervalId)
                resolve()
            }
        }, 100)
    })
}


let authenticatingPromise: Promise<string> | undefined = undefined
let accessToken: string | undefined

function createAuthenticatingPromise() {
    authenticatingPromise = new Promise((resolve, reject) => {
        console.log("Auth: starting authentication")
        auth0?.getAccessTokenSilently({detailedResponse: true})
            .then((response) => {
                console.log("Auth: got token silently", response)
                accessToken = response.access_token
                return response
            })
            .catch((reason: unknown) => {
                console.error("Auth: could not get token silently, redirecting", reason)
                auth0?.loginWithRedirect()
                throw reason
            })
            || console.error("Auth: no auth0 available to get token silently")

        window.setTimeout(() => {
            authenticatingPromise = undefined
            reject()
        }, 300 * 1000)

        let interval: number | undefined = undefined
        interval = window.setInterval(() => {
            if (auth0?.isAuthenticated && accessToken) {
                console.log("Auth: user is authenticated and has access token", accessToken)
                window.clearInterval(interval)
                resolve(accessToken)
            } else {
                console.log("Auth: user not yet authenticated", auth0?.isAuthenticated, accessToken)
            }
        }, 500)
    })
    return authenticatingPromise
}

const authLink = new ApolloLink((operation: Operation, forward: NextLink) => {
    if (!loadingPromise) {
        createLoadingPromise()
    }

    return loadingPromise?.then(() => {
        if (!authenticatingPromise) {
            createAuthenticatingPromise()
        }

        return authenticatingPromise?.then((idTokenClaim: string) => {
            operation.setContext({
                headers: {
                    authorization: `Bearer ${idTokenClaim}`
                }
            })
            return forward(operation)
        })
            .catch((error) => {
                console.error("GraphQL error", error)
                throw new Error(`"GraphQL error: ${error}`)
            })
    })
})
const httpLink = createHttpLink({
    uri: import.meta.env.VITE_GRAPHQL_ENDPOINT as string
})
const cache = new InMemoryCache({
    possibleTypes: result.possibleTypes,

})
const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  },
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  },
  mutate: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
}

export const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    defaultOptions
})
