<script setup lang="ts">
import { computed, inject, reactive, ref } from 'vue'
import { itemSort, keyOrganizationId, keyProjectId } from '@/app'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import type { AttributeMeta, EventType, Query } from '@/generated/graphql'
import { AttributeType } from '@/generated/graphql'
import useVuelidate from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import TextField from '@/components/input/TextField.vue'
import Checkbox from '@/components/input/Checkbox.vue'
import ItemTable from '@/components/items/ItemTable.vue'
import ItemPage from '@/components/items/ItemPage.vue'
import SelectField from '@/components/input/SelectField.vue'
import { useRoute } from 'vue-router'
import AttributeConfigurationField from '@/components/input/AttributeConfigurationField.vue'
import ItemEditIcon from '@/components/items/ItemEditIcon.vue'
import ItemDeleteIcon from '@/components/items/ItemDeleteIcon.vue'

const route = useRoute()
const orgId = inject(keyOrganizationId)!
const projectId = inject(keyProjectId)!
const eventTypeId = computed(() => route.params.eventTypeId)
const fetchQuery = useQuery<Query>(
  gql`
    query getEventTypeAttributes($orgId: ID!, $projectId: ID!, $eventTypeId: ID!) {
      organization(id: $orgId) {
        id
        project(id: $projectId) {
          id
          eventType(id: $eventTypeId) {
            id
            name
            attributeMetas {
              id
              type
              name
              configuration
              position
              optional
            }
          }
        }
      }
    }
  `,
  () => ({
    orgId: orgId.value,
    projectId: projectId.value,
    eventTypeId: eventTypeId.value
  })
)
const eventTypeName = computed(
  () => fetchQuery.result.value?.organization.project.eventType.name || ''
)
const items = computed<AttributeMeta[]>(() =>
  itemSort<AttributeMeta>(
    fetchQuery.result.value?.organization.project.eventType.attributeMetas,
    (i) => i.position.toString().padStart(3, '0')
  )
)

type MutationModel = {
  id?: string
  eventTypeId?: string
  type?: AttributeType
  name?: string
  configuration?: string
  afterId?: number
  optional?: boolean
}

const typeOptions = [
  { title: 'Address', value: 'ADDRESS' },
  { title: 'Checkbox', value: 'TOGGLE' },
  { title: 'Choice', value: 'CHOICE' },
  { title: 'File', value: 'FILE' },
  { title: 'Flight', value: 'FLIGHT' },
  { title: 'Link', value: 'LINK' },
  { title: 'Nocturnal', value: 'NOCTURNAL' },
  { title: 'Number', value: 'INTEGER' },
  { title: 'Resource', value: 'RESOURCE' },
  { title: 'Text', value: 'TEXT' }
]

const createAfterIdOptions = computed(() =>
  items.value.map((i: AttributeMeta) => ({
    title: i.name,
    value: i.id
  }))
)
const createInitialState = {
  id: undefined,
  eventTypeId: eventTypeId.value,
  type: undefined,
  name: undefined,
  configuration: '{}',
  afterId: undefined,
  optional: true
}
const createMutationModel = reactive<MutationModel>({ ...createInitialState })
function resetMutationModel() {
  Object.assign(createMutationModel, createInitialState)
}
const createValidation = useVuelidate<MutationModel>(
  {
    name: { required, min: minLength(3) },
    type: { required }
  },
  createMutationModel
)
const createMutation = useMutation(
  gql`
    mutation addAttributeToEventType(
      $orgId: ID!
      $projectId: ID!
      $command: AddAttributeToEventTypeCommand!
    ) {
      organization(id: $orgId) {
        project(id: $projectId) {
          eventAttributes {
            addAttributeToEventType(input: $command) {
              id
            }
          }
        }
      }
    }
  `,
  () => ({
    refetchQueries: ['getEventTypesList']
  })
)
async function create() {
  return createMutation.mutate({
    command: createMutationModel,
    orgId: orgId.value,
    projectId: projectId.value
  })
}

const updateInitialState = {
  id: undefined,
  eventTypeId: undefined,
  name: undefined,
  configuration: undefined,
  afterId: undefined,
  optional: undefined
}
const updateMutationModel = reactive<MutationModel>({ ...updateInitialState })
const updateType = ref<AttributeType>()
const updateValidation = useVuelidate<MutationModel>(
  {
    name: { required, min: minLength(3) }
  },
  updateMutationModel
)
const updateAfterIdOptions = computed(() =>
  createAfterIdOptions.value.filter((i: { value: string }) => i.value != updateMutationModel.id)
)

const updateOpen = ref(false)
function updateItem(item: AttributeMeta) {
  Object.keys(updateInitialState).forEach((k: string) => {
    updateMutationModel[k] = item[k]
  })
  updateType.value = item.type
  updateOpen.value = true
}
const updateMutation = useMutation(
  gql`
    mutation updateAttributeOnEventType(
      $orgId: ID!
      $projectId: ID!
      $command: UpdateAttributeOnEventTypeCommand!
    ) {
      organization(id: $orgId) {
        project(id: $projectId) {
          eventAttributes {
            updateAttributeOnEventType(input: $command) {
              id
            }
          }
        }
      }
    }
  `
)

async function update() {
  return updateMutation.mutate({
    command: updateMutationModel,
    orgId: orgId.value,
    projectId: projectId.value
  })
}

const deleteItem = ref<EventType>()
const deleteMutation = useMutation(
  gql`
    mutation deleteAttributeFromEventType($orgId: ID!, $projectId: ID!, $id: ID!) {
      organization(id: $orgId) {
        project(id: $projectId) {
          eventAttributes {
            deleteAttributeFromEventType(input: { id: $id }) {
              id
            }
          }
        }
      }
    }
  `
)
function doDelete(id: string) {
  return deleteMutation.mutate({ id, orgId: orgId.value, projectId: projectId.value })
}
</script>

<template>
  <router-link :to="{ name: 'event-types' }">
    <v-icon icon="keyboard_double_arrow_left"></v-icon>
    Back to all event types
  </router-link>

  <item-page
    :title="`Fields for ${eventTypeName}`"
    :fetch="fetchQuery.refetch"
    :create-validation="createValidation"
    :create="create"
    @reset-mutation-model="resetMutationModel()"
    :update-validation="updateValidation"
    :update="update"
    v-model:update-open="updateOpen"
    :delete="doDelete"
    v-model:delete-item="deleteItem"
  >
    <template #items-table>
      <item-table :headers="['Name', 'Type']" :items="items">
        <template #item="{ item }">
          <td>{{ item.name }}</td>
          <td>{{ item.type }}</td>
          <td>
            <item-edit-icon @click="updateItem(item)" />
            <item-delete-icon @click="deleteItem = item" />
          </td>
        </template>
      </item-table>
    </template>
    <template #create-form>
      <text-field
        label="Name"
        required
        v-model="createMutationModel.name"
        :validation="createValidation.name"
      />
      <select-field
        :options="typeOptions"
        label="Type"
        required
        v-model="createMutationModel.type"
        :validation="createValidation.type"
      />
      <select-field
        :options="createAfterIdOptions"
        label="Place after field"
        v-model="createMutationModel.afterId"
        :validation="createValidation.afterId"
      />
      <checkbox label="Optional" v-model="createMutationModel.optional" />
      <attribute-configuration-field
        :type="createMutationModel.type"
        v-model="createMutationModel.configuration"
      />
    </template>
    <template #update-form>
      <text-field
        label="Name"
        required
        v-model="updateMutationModel.name"
        :validation="updateValidation.name"
      />
      <select-field
        :options="updateAfterIdOptions"
        label="Move after field"
        v-model="updateMutationModel.afterId"
      />
      <checkbox label="Optional" v-model="updateMutationModel.optional" />
      <attribute-configuration-field
        :type="updateType"
        v-model="updateMutationModel.configuration"
      />
    </template>
  </item-page>
</template>

<style scoped lang="scss"></style>
