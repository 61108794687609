<script setup lang="ts">
import type { FileAttribute, Query } from '@/generated/graphql'
import gql from 'graphql-tag'
import { inject, ref, watch } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { keyOrganizationId, keyProjectId } from '@/app'
import { keyEvent } from '@/components/events/shared'
import { downloadFile } from '@/components/events/attributes/shared'

const props = defineProps<{
  modelValue: FileAttribute
}>()

const orgId = inject(keyOrganizationId)
const projectId = inject(keyProjectId)
const event = inject(keyEvent)!
const fileId = ref<string>(undefined)
const downloadFileQuery = useQuery<Query>(
  gql`
    query downloadFile($orgId: ID!, $projectId: ID!, $eventId: ID!, $metaId: ID!, $fileId: ID!) {
      organization(id: $orgId) {
        id
        project(id: $projectId) {
          id
          event(id: $eventId) {
            id
            attribute(metaId: $metaId) {
              ... on FileAttribute {
                id
                file(id: $fileId) {
                  id
                  content
                  type
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
  () => ({
    orgId: orgId?.value,
    projectId: projectId?.value,
    eventId: event.value.id,
    metaId: props.modelValue.meta.id,
    fileId: fileId.value
  }),
  () => ({
    enabled: !!fileId.value && !!event.value,
    fetchPolicy: 'no-cache'
  })
)
watch(
  () => downloadFileQuery.result.value,
  () => {
    const attr = downloadFileQuery.result.value?.organization.project.event
      .attribute as FileAttribute
    if (!attr) {
      return
    }

    fileId.value = undefined
    downloadFile(attr.file.name, attr.file.content, attr.file.type)
  },
  { deep: true }
)
</script>

<template>
  <div v-for="f in props.modelValue.files" :key="f.id" class="download">
    <v-btn
      icon="cloud_download"
      size="small"
      variant="elevated"
      density="comfortable"
      @click="fileId = f.id"
    />
    {{ f.name }}
  </div>
</template>

<style scoped lang="scss">
.download {
  margin: 2px 0;
}
</style>
