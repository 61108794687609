<script setup lang="ts">
import type {
  AttributeMeta,
  SetTextAttributeOnEventCommand,
  TextAttribute,
  UnsetAttributeCommand
} from '@/generated/graphql'
import { inject, reactive, watch } from 'vue'
import { v4 } from 'uuid'
import TextField from '@/components/input/TextField.vue'
import type { AttributeConfiguration } from '@/components/input/AttributeConfigurationField.vue'
import TextAreaField from '@/components/input/TextAreaField.vue'
import { parseConfig } from '@/components/events/attributes/shared'
import { keyEventDuplication } from '@/components/events/shared'

const props = defineProps<{
  eventId: string
  meta: AttributeMeta
  seed?: TextAttribute
}>()
const emit = defineEmits<{
  command: [value: SetTextAttributeOnEventCommand | UnsetAttributeCommand]
}>()

type MutationModel = {
  id?: string
  attributeId: string
  eventId: string
  text?: string
}
const initialState: MutationModel = {
  id: props.seed?.id || v4(),
  attributeId: props.meta.id,
  eventId: props.eventId,
  text: props.seed?.text
}
const mutationModel = reactive<MutationModel>({ ...initialState })
const unsetCommand: UnsetAttributeCommand = {
  eventId: props.eventId,
  attributeId: initialState.id!
}
const isDuplication = inject(keyEventDuplication)!
function isComplete(model: MutationModel) {
  return model.text?.length || 0 > 0
}
watch(
  mutationModel,
  () => emit('command', isComplete(mutationModel) ? mutationModel : unsetCommand),
  { immediate: isDuplication.value }
)

const config = parseConfig(props.meta.configuration)
</script>

<template>
  <text-field v-if="!config.isMultiline" :label="props.meta.name" v-model="mutationModel.text" />
  <text-area-field
    v-if="config.isMultiline"
    :label="props.meta.name"
    v-model="mutationModel.text"
  />
</template>

<style scoped lang="scss"></style>
