<script setup lang="ts" generic="MM">
import type { Validation, ValidationArgs } from '@vuelidate/core'
import {ref, watch} from 'vue'

const props = defineProps<{
  modelValue: boolean
  create?: boolean
  validation?: Validation<ValidationArgs, MM>
  saveLoading?: boolean
}>()
const emit = defineEmits<{
  'update:modelValue': [value: boolean]
  save: []
  cancel: []
  open: []
}>()

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue) {
      emit('open')
    }
  },
  { immediate: true }
)

function save() {
  props.validation?.$touch()
  if (props.validation?.$invalid) {
    //scroll to the first error
    const elementsInErrors = document.getElementsByClassName('form-field-invalid')
    if (elementsInErrors && elementsInErrors.length > 0) {
      elementsInErrors[0].scrollIntoView({ behavior: 'smooth' })
    }
    return
  }

  emit('save')
}
function cancel() {
  emit('cancel')
  emit('update:modelValue', false)
  props.validation?.$reset()
}
</script>

<template>
  <v-dialog
    max-width="650"
    :model-value="props.modelValue"
    @update:modelValue="(v) => $emit('update:modelValue', v)"
    @keydown.ctrl.enter.stop="save"
    @keydown.meta.enter.stop="save"
  >
    <v-card class="edit-dialog-content">
      <v-card-title>
        <slot name="title">{{ props.create ? 'Create' : 'Edit' }}</slot>
      </v-card-title>
      <v-card-text>
        <div class="edit-dialog-form">
          <slot name="form">
            <v-form>
              <slot name="default" />
            </v-form>
          </slot>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancel">Cancel</v-btn>
        <v-btn @click="save" color="primary" :loading="props.saveLoading" :disabled="props.saveLoading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.edit-dialog-form {
  min-width: 500px;
}
</style>
