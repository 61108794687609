<script lang="ts" setup>
import { computed } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue'

const $auth0 = useAuth0()
const loggingIn = computed(() => $auth0.isLoading.value || !$auth0.isAuthenticated.value)

const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID as string
</script>

<template>
  <v-dialog v-model="loggingIn" width="300" persistent>
    <v-card>
      <v-card-title>Log in</v-card-title>
      <v-card-text> Your login is being processed </v-card-text>
    </v-card>
  </v-dialog>
</template>
