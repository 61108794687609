import gql from 'graphql-tag'

export const getEventQuery = gql`
  query getEvent($orgId: ID!, $projectId: ID!, $eventId: ID!) {
    organization(id: $orgId) {
      id
      project(id: $projectId) {
        id
        event(id: $eventId) {
          id
          name
          start
          end
          startTimezone
          endTimezone
          type {
            id
            name
          }
          attributes {
            ... on Attribute {
              meta {
                id
                name
                type
                configuration
                position
              }
            }
            ... on ChoiceAttribute {
              id
              choices
            }
            ... on FlightAttribute {
              id
              baggageDetails
              bookingReference
              carrierCode
              comments
              lookupEnabled
              departureDetails
              departureAirportIataCode
              departureDateTimeScheduled
              departureTerminal
              departureGate
              arrivalAirportIataCode
              arrivalDateTimeScheduled
              arrivalTerminal
              arrivalGate
              flightNumber
              operationalSuffix
              seatDetails
              pdf
            }
            ... on IntegerAttribute {
              id
              integer
            }
            ... on NocturnalAttribute {
              id
              active
            }
            ... on ResourceAttribute {
              id
              resources {
                id
                name
                description
              }
            }
            ... on TextAttribute {
              id
              text
            }
            ... on ToggleAttribute {
              id
              active
            }
            ... on AddressAttribute {
              id
              fullAddress
              comment
            }
            ... on LinkAttribute {
              id
              targets {
                target
                name
              }
            }
            ... on FileAttribute {
              id
              files {
                id
                name
                type
              }
            }
          }
        }
      }
    }
  }
`
