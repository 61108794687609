<script setup lang="ts">
import type {
  Address,
  AddressAttribute,
  AttributeMeta,
  Query,
  SetAddressAttributeOnEventCommand,
  UnsetAttributeCommand
} from '@/generated/graphql'
import { computed, inject, reactive, ref, watch } from 'vue'
import { v4 } from 'uuid'
import TextField from '@/components/input/TextField.vue'
import type { AttributeConfiguration } from '@/components/input/AttributeConfigurationField.vue'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { debounce } from 'underscore'
import { keyEventDuplication } from '@/components/events/shared'

const props = defineProps<{
  eventId: string
  meta: AttributeMeta
  seed?: AddressAttribute
}>()
const emit = defineEmits<{
  command: [value: SetAddressAttributeOnEventCommand | UnsetAttributeCommand]
}>()

type MutationModel = {
  id?: string
  attributeId: string
  eventId: string
  comment?: string
  fullAddress?: string
}
const initialState: MutationModel = {
  id: props.seed?.id || v4(),
  attributeId: props.meta.id,
  eventId: props.eventId,
  comment: props.seed?.comment,
  fullAddress: props.seed?.fullAddress
}
const mutationModel = reactive<MutationModel>({ ...initialState })
const unsetCommand: UnsetAttributeCommand = {
  eventId: props.eventId,
  attributeId: initialState.id!
}
function isComplete(model: MutationModel) {
  return model.fullAddress?.length || 0 > 0
}
const isDuplication = inject(keyEventDuplication)!
watch(
  mutationModel,
  () => emit('command', isComplete(mutationModel) ? mutationModel : unsetCommand),
  { immediate: isDuplication.value }
)

const searchOpen = ref(false)
const searchText = ref<string>()
const searchSelect = ref<string>()

const debouncedSearchText = ref<string>()
const debouncer = debounce(() => {
  debouncedSearchText.value = searchText.value
}, 500)
watch(searchText, debouncer)
const findAddressQuery = useQuery<Query>(
  gql`
    query findAddress($partial: String!) {
      findAddressByText(partial: $partial) {
        fullAddress
        name
      }
    }
  `,
  () => ({
    partial: debouncedSearchText.value
  }),
  () => ({
    enabled: debouncedSearchText.value?.length || 0 > 0
  })
)
const loading = computed(() => findAddressQuery.loading.value)
const foundAddresses = computed(
  () =>
    findAddressQuery.result.value?.findAddressByText.map((a: Address) => {
      let title = a.fullAddress
      if (a.name.toLowerCase() !== searchText.value?.toLowerCase()) {
        title = `${a.name} - ${title}`
      }
      return {
        value: a.fullAddress,
        title
      }
    }) || []
)

function acceptSearch() {
  mutationModel.fullAddress = searchSelect.value
  searchOpen.value = false
  searchText.value = undefined
  searchSelect.value = undefined
}
</script>

<template>
  <text-field :label="props.meta.name" v-model="mutationModel.fullAddress">
    <template #append>
      <v-icon icon="search" @click="searchOpen = true" />
    </template>
  </text-field>
  <text-field
    v-if="isComplete(mutationModel)"
    :label="`${props.meta.name} comment`"
    v-model="mutationModel.comment"
  />

  <v-dialog
    v-model="searchOpen"
    width="550"
    @keydown.ctrl.enter.stop="acceptSearch()"
    @keydown.meta.enter.stop="acceptSearch()"
  >
    <v-card>
      <v-card-title>Search address</v-card-title>
      <v-card-text>
        <v-autocomplete
          auto-select-first
          autofocus
          hide-details
          hide-no-data
          no-filter
          variant="solo"
          v-model="searchSelect"
          v-model:search="searchText"
          :items="foundAddresses"
          :loading="loading"
        ></v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="searchOpen = false">Cancel</v-btn>
        <v-btn @click="acceptSearch()" color="primary">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
