<script setup lang="ts" generic="MM">
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, inject } from 'vue'
import { itemSort, keyOrganizationId, keyProjectId } from '@/app'
import SelectField from '@/components/input/SelectField.vue'
import { Validation, ValidationArgs } from '@vuelidate/core'

type ShortEventType = {
  id: string
  archived: boolean
  name: string
}

const props = defineProps<{
  label: string
  required?: boolean
  modelValue?: string
  validation: Validation<ValidationArgs, MM>
}>()
const emit = defineEmits<{
  'update:modelValue': [value: string]
}>()

const orgId = inject(keyOrganizationId)
const projectId = inject(keyProjectId)
const fetchQuery = useQuery(
  gql`
    query getEventTypesList($orgId: ID!, $projectId: ID!) {
      organization(id: $orgId) {
        project(id: $projectId) {
          eventTypes {
            id
            archived
            name
          }
        }
      }
    }
  `,
  () => ({
    orgId: orgId?.value,
    projectId: projectId?.value
  })
)
const types = computed<ShortEventType[]>(() =>
  itemSort<ShortEventType>(
    fetchQuery.result.value?.organization.project.eventTypes || [],
    (e: ShortEventType) => e.name
  )
)
const options = computed(() =>
  types.value.filter((t) => !t.archived).map((t) => ({ value: t.id, title: t.name }))
)
</script>

<template>
  <select-field
    :options="options"
    :label="props.label"
    :required="props.required"
    :validation="props.validation"
    :model-value="props.modelValue"
    @update:modelValue="(v) => $emit('update:modelValue', v)"
  />
</template>

<style scoped lang="scss"></style>
