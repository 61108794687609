<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'

const $route = useRoute()
const mutation = useMutation(
  gql`
    mutation setCode($code: String!, $state: String!) {
      oauth {
        googleSheets(code: $code, state: $state)
      }
    }
  `,
  () => ({
    variables: {
      code: $route.query.code,
      state: $route.query.state
    }
  })
)
const channel = new BroadcastChannel('oauth')

mutation.mutate().then(() => {
  channel.postMessage('authenticated')
  self.close()
})
</script>

<template>
  <v-dialog persistent>
    <v-card>
      <v-card-title>Google Sheets authentication</v-card-title>
      <v-card-text>
        We're processing your Google Sheets authentication. This window will close automatically
        when we're done.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
