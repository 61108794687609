<script setup lang="ts">
import { computed, inject, reactive, ref } from 'vue'
import { itemSort, keyOrganizationId, keyProjectId, toDateTime } from '@/app'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import type { ProjectAccessToken, Query, Resource } from '@/generated/graphql'
import useVuelidate from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import TextField from '@/components/input/TextField.vue'
import ItemTable from '@/components/items/ItemTable.vue'
import ItemPage from '@/components/items/ItemPage.vue'
import ItemDeleteIcon from '@/components/items/ItemDeleteIcon.vue'
import ItemEditIcon from '@/components/items/ItemEditIcon.vue'

const orgId = inject(keyOrganizationId)!
const projectId = inject(keyProjectId)!
const fetchQuery = useQuery<Query>(
  gql`
    query getAccessTokens($orgId: ID!, $projectId: ID!) {
      organization(id: $orgId) {
        id
        project(id: $projectId) {
          id
          accessTokens {
            id
            name
            lastUsed
            token
          }
        }
      }
    }
  `,
  () => ({
    orgId: orgId.value,
    projectId: projectId.value
  })
)
const items = computed(() =>
  itemSort<ProjectAccessToken>(
    fetchQuery.result.value?.organization.project.accessTokens,
    (i) => i.name
  )
)

type MutationModel = {
  id?: string
  name?: string
}
const initialState = {
  id: undefined,
  name: undefined
}
const mutationModel = reactive<MutationModel>({ ...initialState })
function resetMutationModel() {
  Object.assign(mutationModel, initialState)
}
const validation = useVuelidate<MutationModel>(
  {
    name: { required, min: minLength(3) }
  },
  mutationModel
)

const createMutation = useMutation(
  gql`
    mutation createAccessToken(
      $orgId: ID!
      $projectId: ID!
      $command: CreateProjectAccessTokenCommand!
    ) {
      organization(id: $orgId) {
        project(id: $projectId) {
          createAccessToken(input: $command) {
            id
          }
        }
      }
    }
  `
)
async function create() {
  return createMutation.mutate({
    command: mutationModel,
    orgId: orgId.value,
    projectId: projectId.value
  })
}

const updateOpen = ref(false)
function updateItem(item: Resource) {
  Object.keys(initialState).forEach((k: string) => {
    mutationModel[k] = item[k]
  })
  updateOpen.value = true
}
const updateMutation = useMutation(
  gql`
    mutation updateAccessToken(
      $orgId: ID!
      $projectId: ID!
      $command: UpdateProjectAccessTokenCommand!
    ) {
      organization(id: $orgId) {
        project(id: $projectId) {
          updateAccessToken(input: $command) {
            id
          }
        }
      }
    }
  `
)

async function update() {
  return updateMutation.mutate({
    command: mutationModel,
    orgId: orgId.value,
    projectId: projectId.value
  })
}

const deleteItem = ref<Resource>()
const deleteMutation = useMutation(
  gql`
    mutation deleteAccessToken($orgId: ID!, $projectId: ID!, $id: ID!) {
      organization(id: $orgId) {
        project(id: $projectId) {
          deleteAccessToken(input: { id: $id }) {
            id
          }
        }
      }
    }
  `
)
function doDelete(id: string) {
  return deleteMutation.mutate({ id, orgId: orgId.value, projectId: projectId.value })
}

const selectedToken = ref<string>()

async function copyInstructionsToClipboard() {
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  const selection = window.getSelection()!
  const container = document.querySelector('#app-instructions')!

  if (selection.rangeCount > 0) {
    selection.removeAllRanges()
  }

  const range = document.createRange()
  range.selectNode(container)
  selection.addRange(range)
  document.execCommand('copy')
  selection.removeAllRanges()
}
</script>

<template>
  <item-page
    title="Access tokens"
    :fetch="fetchQuery.refetch"
    :create-validation="validation"
    :create="create"
    @reset-mutation-model="resetMutationModel()"
    :update-validation="validation"
    :update="update"
    v-model:update-open="updateOpen"
    :delete="doDelete"
    v-model:delete-item="deleteItem"
    delete-warning="If you remove this token, it cannot be recreated so someone using it loses access to this project."
  >
    <template #items-table>
      <item-table :headers="['Name', 'Last used']" :items="items">
        <template #item="{ item }">
          <td>{{ item.name }}</td>
          <td>{{ toDateTime(item.lastUsed) }}</td>
          <td>
            <item-edit-icon @click="updateItem(item)" />
            <item-delete-icon @click="deleteItem = item" />
            <v-icon icon="visibility" title="Show token" @click="selectedToken = item.token" />
          </td>
        </template>
      </item-table>
    </template>
    <template #create-form>
      <text-field
        label="Name"
        required
        v-model="mutationModel.name"
        :validation="validation.name"
      />
    </template>
    <template #update-form>
      <text-field
        label="Name"
        required
        v-model="mutationModel.name"
        :validation="validation.name"
      />
    </template>
  </item-page>

  <v-dialog
    :model-value="!!selectedToken"
    @update:modelValue="selectedToken = undefined"
    width="650"
  >
    <v-card>
      <v-card-title>
        Token and instructions
        <v-btn
          @click="copyInstructionsToClipboard()"
          icon="content_copy"
          title="Copy instructions"
          style="position: absolute; right: 10px"
        />
      </v-card-title>
      <v-card-text>
        <div id="app-instructions">
          <p>There are 2 steps to use the app: installing it and logging in.</p>

          <h3>Installation</h3>

          <p>
            Go to
            <a href="https://calendar.calena.app">https://calendar.calena.app</a>
            to open the app. Installing it is a bit different per browser and operating system:
          </p>

          <h4>Firefox on Android</h4>
          <p>
            Open the browser menu at the top right (the three dots) and click
            <code>Install</code>
          </p>

          <h4>Chrome on Android</h4>
          <p>
            You'll get a message at the bottom of your screen asking to add the app to your home
            screen. If you missed it, open the browser menu at the top right (the three dots)
            <code>Add to Home Screen</code>
          </p>

          <h4>Safari on iPhone</h4>
          <p>Click on the share icon and click <code>Add to Home Screen </code></p>

          <h4>Chrome on iPhone</h4>
          <p>This combination does not support the technology we use to run the app.</p>

          <h4>Browsers on desktop</h4>

          <p>Simply bookmark the website and come back to it, even if you're offline.</p>

          <h3>Log in</h3>

          <p>
            If you open the app for the first time, you'll be prompted for an access token. Enter
            this:
          </p>

          <p>
            <code>{{ selectedToken }}</code>
          </p>

          <p>
            ...and log in.
            <strong> Do not share the access token with anyone, ever! It is personal </strong>
            and more can be made for each individual that needs access to this calendar.
          </p>

          <p>
            If you dismissed the prompt, you can reload the page or open the left-hand menu, click
            on the Add Calendar button and enter the access token
          </p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="selectedToken = undefined" color="primary">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
p {
  margin-bottom: 1em;
}
</style>
