<script setup lang="ts">
import type { IntegrationIcalImportUrl } from '@/generated/graphql'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { inject, ref, watch } from 'vue'
import { keyOrganizationId, keyProjectId } from '@/app'

const props = defineProps<{
  modelValue: IntegrationIcalImportUrl
}>()
const emit = defineEmits<{
  'update:modelValue': [value?: IntegrationIcalImportUrl]
}>()

const orgId = inject(keyOrganizationId)!
const projectId = inject(keyProjectId)!
const deleteEventsMutation = useMutation(
  gql`
    mutation deleteIcalImportUrlEvents($orgId: ID!, $projectId: ID!, $id: ID!) {
      organization(id: $orgId) {
        project(id: $projectId) {
          integrations {
            icalImport {
              deleteAllEventsFromUrl(id: $id)
            }
          }
        }
      }
    }
  `
)

const isOpen = ref(true)
watch(
  () => isOpen,
  (v: boolean) => {
    if (!v) {
      emit('update:modelValue', undefined)
    }
  }
)
const notificationSuccess = ref(false)
const notificationFail = ref(false)

function cancelDeleteAllEvents() {
  isOpen.value = false
  emit('update:modelValue', undefined)
}
function doDeleteAllEvents() {
  deleteEventsMutation
    .mutate({ id: props.modelValue.id, orgId: orgId.value, projectId: projectId.value })
    .then(() => {
      isOpen.value = false
      notificationSuccess.value = true
      emit('update:modelValue', undefined)
    })
    .catch((reason: unknown) => {
      notificationFail.value = true
      console.log("Could not delete all events of ical integration", reason)
      throw new Error(`"Could not delete all events of ical integration: ${reason}`)
    })
}
</script>

<template>
  <v-dialog max-width="500px" v-model="isOpen">
    <v-card>
      <v-card-title>Delete all events created by {{ props.modelValue.name }}</v-card-title>

      <v-card-text>
        <v-alert type="warning" title="Be careful" variant="outlined" style="margin-bottom: 1em">
          This will remove <strong>all events</strong> created by the import of
          <a :href="props.modelValue.url" target="_blank" rel="noopener">this url</a>.
        </v-alert>

        <v-alert
          type="info"
          title="Only events are removed"
          variant="outlined"
          style="margin-bottom: 1em"
        >
          Only the events are removed. If you do not remove the complete import url as well, the
          events will reappear with the next import.
        </v-alert>

        <p>Are you sure you want to delete all events created by {{ props.modelValue.name }}?</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancelDeleteAllEvents()">Cancel</v-btn>
        <v-btn @click="doDeleteAllEvents()" color="primary">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
