<script setup lang="ts">
import type { SimpleItem } from '@/app'

const props = defineProps<{
  modelValue: boolean
  item: SimpleItem
  disabled?: boolean
  warning?: string
}>()
const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'delete', id: string): void
  (e: 'cancel'): void
}>()

function deleteItem() {
  emit('delete', props.item.id)
  emit('update:modelValue', false)
}
function cancel() {
  emit('cancel')
  emit('update:modelValue', false)
}
</script>

<template>
  <v-dialog
    max-width="500px"
    :model-value="props.modelValue"
    @update:modelValue="(v) => $emit('update:modelValue', v)"
    @keydown.ctrl.enter.stop="deleteItem"
    @keydown.meta.enter.stop="deleteItem"
  >
    <v-card>
      <v-card-title>Delete {{ props.item.name }}</v-card-title>

      <v-card-text>
        <v-alert
          v-if="!!props.warning"
          type="warning"
          title="Be careful"
          variant="outlined "
          style="margin-bottom: 1em"
        >
          {{ props.warning }}
        </v-alert>
        <p>Are you sure you want to delete {{ props.item.name }}?</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancel">Cancel</v-btn>
        <v-btn @click="deleteItem" color="primary">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
