<script setup lang="ts">
import type {
  AttributeMeta,
  ChoiceAttribute,
  SetChoiceAttributesOnEventCommand,
  UnsetAttributeCommand
} from '@/generated/graphql'
import { computed, inject, reactive, watch } from 'vue'
import type { AttributeConfiguration } from '@/components/input/AttributeConfigurationField.vue'
import { parseConfig } from '@/components/events/attributes/shared'
import SelectField from '@/components/input/SelectField.vue'
import { keyEventDuplication } from '@/components/events/shared'

const props = defineProps<{
  eventId: string
  meta: AttributeMeta
  seed?: ChoiceAttribute
}>()
const emit = defineEmits<{
  command: [value: SetChoiceAttributesOnEventCommand | UnsetAttributeCommand]
}>()

type MutationModel = {
  attributeId: string
  eventId: string
  choices?: string[]
}
const initialState: MutationModel = {
  attributeId: props.meta.id,
  eventId: props.eventId,
  choices: props.seed?.choices
}
const mutationModel = reactive<MutationModel>({ ...initialState })
const isDuplication = inject(keyEventDuplication)!
watch(mutationModel, () => emit('command', mutationModel), { immediate: isDuplication.value })

const config = parseConfig(props.meta.configuration)
const options = computed(
  () =>
    config.value?.choices?.map((o: string) => ({
      value: o,
      title: o
    })) || []
)
</script>

<template>
  <select-field
    :options="options"
    :label="props.meta.name"
    v-model="mutationModel.choices"
    :multiple="config?.isMultiChoice"
  />
</template>

<style scoped lang="scss"></style>
