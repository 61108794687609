<script setup lang="ts">
import { inject } from 'vue'
import { authzIsAdmin, keyOrganizationId, keyProjectId } from '@/app'

const props = defineProps<{
  modelValue: boolean
}>()
defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const projectId = inject(keyProjectId)!
const orgId = inject(keyOrganizationId)!
</script>

<template>
  <v-navigation-drawer
    nav
    class="main-navigation-drawer"
    theme="dark"
    :model-value="props.modelValue"
    @update:modelValue="$emit('update:modelValue', false)"
  >
    <template v-if="orgId && authzIsAdmin">
      <h3>Organization settings</h3>
      <v-list density="compact">
        <router-link :to="{ name: 'authorization' }">
          <v-list-item title="Users and authorization" />
        </router-link>
        <router-link :to="{ name: 'projects' }">
          <v-list-item title="Projects" />
        </router-link>
      </v-list>
    </template>
    <template v-if="projectId && authzIsAdmin">
      <h3>Project settings</h3>
      <v-list density="compact">
        <router-link :to="{ name: 'event-types' }">
          <v-list-item title="Event types" />
        </router-link>
        <router-link :to="{ name: 'access-tokens' }">
          <v-list-item title="Access tokens" />
        </router-link>
      </v-list>

      <h3>Integrations</h3>
      <v-list density="compact">
        <router-link :to="{ name: 'integration-travel-case' }">
          <v-list-item title="Travel Case" />
        </router-link>
        <router-link :to="{ name: 'integration-google-sheets-import' }">
          <v-list-item title="Google Sheets import" />
        </router-link>
        <router-link :to="{ name: 'integration-ical-import' }">
          <v-list-item title="ICal import" />
        </router-link>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<style lang="scss">
.main-navigation-drawer .v-navigation-drawer__content {
  padding: 15px 5px;

  a,
  a:visited {
    color: var(--v-theme-primary);
    text-decoration: none;
  }
}
</style>
