<script setup lang="ts">
import { computed, inject, reactive, ref } from 'vue'
import { itemSort, keyOrganizationId } from '@/app'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import type { Project, Query, Resource } from '@/generated/graphql'
import useVuelidate from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import TextField from '@/components/input/TextField.vue'
import ItemTable from '@/components/items/ItemTable.vue'
import ItemPage from '@/components/items/ItemPage.vue'
import ItemDeleteIcon from '@/components/items/ItemDeleteIcon.vue'
import ItemEditIcon from '@/components/items/ItemEditIcon.vue'
import SelectField from '@/components/input/SelectField.vue'
import Checkbox from '@/components/input/Checkbox.vue'

const orgId = inject(keyOrganizationId)!
const fetchQuery = useQuery<Query>(
  gql`
    query getProjects($orgId: ID!) {
      organization(id: $orgId) {
        projects(withArchived: true) {
          id
          name
          archived
          canDelete
        }
      }
    }
  `,
  () => ({
    orgId: orgId.value
  })
)
const items = computed(() =>
  itemSort<Project>(fetchQuery.result.value?.organization.projects, (i) => i.name)
)
const cloneOptions = computed(() =>
  items.value.map((p: Project) => ({ title: p.name, value: p.id }))
)

const validationRules = {
  name: { required, min: minLength(3) }
}
type MutationModel = {
  id?: string
  name?: string
}

type CreateMutationModel = {
  cloneFromProject?: string
}
const createInitialState: MutationModel & CreateMutationModel = {
  id: undefined,
  name: undefined,
  cloneFromProject: undefined
}
const createMutationModel = reactive<CreateMutationModel & MutationModel>({ ...createInitialState })
function resetMutationModel() {
  Object.assign(createMutationModel, createInitialState)
}
const createValidation = useVuelidate<CreateMutationModel & MutationModel>(
  validationRules,
  createMutationModel
)

const createMutation = useMutation(
  gql`
    mutation createProject($orgId: ID!, $command: CreateProjectCommand!) {
      organization(id: $orgId) {
        createProject(input: $command) {
          id
        }
      }
    }
  `,
  { refetchQueries: ['scopePickerList'] }
)
async function create() {
  return createMutation.mutate({
    command: createMutationModel,
    orgId: orgId.value
  })
}

type UpdateMutationModel = {
  archived?: boolean
}
const updateOpen = ref(false)
const updateInitialState: MutationModel & UpdateMutationModel = {
  id: undefined,
  name: undefined,
  archived: undefined
}
const updateMutationModel = reactive<MutationModel & UpdateMutationModel>({ ...updateInitialState })
const updateValidation = useVuelidate<MutationModel & UpdateMutationModel>(
  validationRules,
  updateMutationModel
)
function updateItem(item: Project) {
  Object.keys(updateInitialState).forEach((k: string) => {
    updateMutationModel[k] = item[k]
  })
  updateOpen.value = true
}
const updateMutation = useMutation(
  gql`
    mutation updateProject($orgId: ID!, $command: UpdateProjectCommand!) {
      organization(id: $orgId) {
        updateProject(input: $command) {
          id
        }
      }
    }
  `,
  { refetchQueries: ['scopePickerList'] }
)

async function update() {
  return updateMutation.mutate({
    orgId: orgId.value,
    command: updateMutationModel
  })
}

const deleteItem = ref<Resource>()
const deleteMutation = useMutation(
  gql`
    mutation deleteProject($orgId: ID!, $id: ID!) {
      organization(id: $orgId) {
        deleteProject(input: { id: $id }) {
          id
        }
      }
    }
  `,
  { refetchQueries: ['scopePickerList'] }
)
function doDelete(id: string) {
  return deleteMutation.mutate({ id, orgId: orgId.value })
}
</script>

<template>
  <item-page
    title="Projects"
    :fetch="fetchQuery.refetch"
    :create-validation="createValidation"
    :create="create"
    @reset-mutation-model="resetMutationModel()"
    :update-validation="updateValidation"
    :update="update"
    v-model:update-open="updateOpen"
    :delete="doDelete"
    v-model:delete-item="deleteItem"
    delete-warning="If you delete the project, it is lost forever, including all its events, event types, resources, settings, etc. This cannot be undone!"
  >
    <template #items-table>
      <item-table :headers="['Name']" :items="items">
        <template #item="{ item }">
          <td>{{ item.name }} <v-icon v-if="item.archived" icon="inventory_2" /></td>
          <td>
            <item-edit-icon @click="updateItem(item)" />
            <item-delete-icon
              :disable-reason="
                item.canDelete
                  ? undefined
                  : 'To prevent mistakes, only projects that contain no data can be deleted.'
              "
              @click="deleteItem = item"
            />
          </td>
        </template>
      </item-table>
    </template>
    <template #create-form>
      <text-field
        label="Name"
        required
        v-model="createMutationModel.name"
        :validation="createValidation.name"
      />
      <select-field
        label="Clone from project"
        v-model="createMutationModel.cloneFromProject"
        :options="cloneOptions"
      />
    </template>
    <template #update-form>
      <text-field
        label="Name"
        required
        v-model="updateMutationModel.name"
        :validation="updateValidation.name"
      />
      <checkbox label="Archived" v-model="updateMutationModel.archived" />
    </template>
  </item-page>
</template>

<style scoped lang="scss"></style>
