<script setup lang="ts">
import { inject, Ref, ref } from 'vue'
import { keyOrganizationId, keyProjectId } from '@/app'
import { useMutation } from '@vue/apollo-composable'
import type { Query } from '@/generated/graphql'
import gql from 'graphql-tag'
import NotificationDeleted from '@/components/notifications/NotificationDeleted.vue'
import NotificationFailed from '@/components/notifications/NotificationFailed.vue'

const props = defineProps<{
  modelValue: boolean
  ids: string[]
}>()
const emit = defineEmits<{
  'update:modelValue': [value: boolean]
  deleted: []
}>()

const orgId = inject<Ref<string>>(keyOrganizationId)!
const projectId = inject<Ref<string>>(keyProjectId)!
const deleteMutation = useMutation<Query>(gql`
  mutation deleteMultiEvents($orgId: ID!, $projectId: ID!, $id: ID!) {
    organization(id: $orgId) {
      project(id: $projectId) {
        events {
          deleteEvent(input: { id: $id }) {
            id
          }
        }
      }
    }
  }
`)

function cancelDeleteAllEvents() {
  emit('update:modelValue', false)
}

const failedNotification = ref(false)
const deletedNotification = ref(false)
function doDeleteAllEvents() {
  const mutations = props.ids.map((id: string) =>
    deleteMutation.mutate({
      orgId: orgId.value,
      projectId: projectId.value,
      id
    })
  )

  Promise.all(mutations)
    .then(() => {
      deletedNotification.value = true
      emit('deleted')
      emit('update:modelValue', false)
    })
    .catch((reason: unknown) => {
      failedNotification.value = true
      console.log("Could not delete multiple events", reason)
      throw new Error(`"Could not delete multiple events: ${reason}`)
    })
}
</script>

<template>
  <v-dialog
    max-width="500px"
    :model-value="props.modelValue"
    @update:modelValue="(v) => emit('update:modelValue', v)"
  >
    <v-card>
      <v-card-title>Delete all {{ props.ids.length }} events</v-card-title>

      <v-card-text>
        <p>Are you sure you want to delete all the selected events?</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancelDeleteAllEvents()">Cancel</v-btn>
        <v-btn @click="doDeleteAllEvents()" color="primary">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <notification-deleted v-model="deletedNotification" />
  <notification-failed v-model="failedNotification" />
</template>

<style scoped lang="scss"></style>
