<script setup lang="ts">
import { computed, inject, reactive, ref, watch } from 'vue'
import { itemSort, keyOrganizationId, keyProjectId } from '@/app'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import type { AttributeMeta, EventType, IntegrationIcalImportUrl, Query } from '@/generated/graphql'
import useVuelidate from '@vuelidate/core'
import { minLength, required, url } from '@vuelidate/validators'
import TextField from '@/components/input/TextField.vue'
import ItemTable from '@/components/items/ItemTable.vue'
import ItemPage from '@/components/items/ItemPage.vue'
import ItemDeleteIcon from '@/components/items/ItemDeleteIcon.vue'
import SelectField from '@/components/input/SelectField.vue'
import DeleteAllEventsDialog from '@/components/integrations/ical-import/DeleteAllEventsDialog.vue'
import RunViewer from '@/components/integrations/ical-import/RunViewer.vue'

const orgId = inject(keyOrganizationId)!
const projectId = inject(keyProjectId)!
const fetchQuery = useQuery<Query>(
  gql`
    query getIcalImports($orgId: ID!, $projectId: ID!) {
      organization(id: $orgId) {
        id
        project(id: $projectId) {
          id
          eventTypes {
            id
            name
            attributeMetas {
              id
              type
              name
            }
          }
          integrations {
            icalImport {
              urls {
                id
                name
                url
                eventType {
                  id
                  name
                }
                descriptionFieldMeta {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
  () => ({
    orgId: orgId.value,
    projectId: projectId.value
  })
)
const items = computed(() =>
  itemSort<IntegrationIcalImportUrl>(
    fetchQuery.result.value?.organization.project.integrations.icalImport.urls,
    (i) => i.name
  )
)
const eventTypes = computed<EventType[]>(
  () => fetchQuery.result.value?.organization.project.eventTypes || []
)
const eventTypeOptions = computed(() =>
  itemSort<EventType>(eventTypes.value, (t) => t.name).map((e: EventType) => ({
    title: e.name,
    value: e.id
  }))
)

type MutationModel = {
  id?: string
  name?: string
  url?: string
  eventTypeId?: string
  descriptionFieldMetaId?: string
}
const initialState = {
  id: undefined,
  name: undefined,
  url: undefined,
  eventTypeId: undefined,
  descriptionFieldMetaId: undefined
}
const mutationModel = reactive<MutationModel>({ ...initialState })
function resetMutationModel() {
  Object.assign(mutationModel, initialState)
}
const validation = useVuelidate<MutationModel>(
  {
    name: { required, min: minLength(3) },
    url: { required, url },
    eventTypeId: { required },
    descriptionFieldMetaId: { required }
  },
  mutationModel
)
watch(
  () => mutationModel.eventTypeId,
  () => {
    mutationModel.descriptionFieldMetaId = undefined
    validation.value.descriptionFieldMetaId.$reset()
  }
)

const descriptionFieldOptions = computed(() => {
  if (!mutationModel.eventTypeId) {
    return []
  }

  return itemSort(
    eventTypes.value.find((e: EventType) => e.id == mutationModel.eventTypeId).attributeMetas,
    (a: AttributeMeta) => a.name
  )
    .filter((a: AttributeMeta) => a.type == 'TEXT')
    .map((a: AttributeMeta) => ({
      title: a.name,
      value: a.id
    }))
})

const createMutation = useMutation(
  gql`
    mutation createIcalImportUrl(
      $orgId: ID!
      $projectId: ID!
      $command: CreateIntegrationIcalImportUrl!
    ) {
      organization(id: $orgId) {
        project(id: $projectId) {
          integrations {
            icalImport {
              createUrl(command: $command) {
                id
              }
            }
          }
        }
      }
    }
  `
)
async function create() {
  return createMutation.mutate({
    command: mutationModel,
    orgId: orgId.value,
    projectId: projectId.value
  })
}

const deleteItem = ref<IntegrationIcalImportUrl>()
const deleteMutation = useMutation(
  gql`
    mutation deleteIcalImportUrl($orgId: ID!, $projectId: ID!, $id: ID!) {
      organization(id: $orgId) {
        project(id: $projectId) {
          integrations {
            icalImport {
              deleteUrl(id: $id)
            }
          }
        }
      }
    }
  `
)
function doDelete(id: string) {
  return deleteMutation.mutate({ id, orgId: orgId.value, projectId: projectId.value })
}

const deleteAllEventsUrl = ref<IntegrationIcalImportUrl>()
const runViewerUrl = ref<IntegrationIcalImportUrl>()
const fakeUpdateOpen = ref(false)
</script>

<template>
  <item-page
    title="ICal Imports"
    :fetch="fetchQuery.refetch"
    :create-validation="validation"
    :create="create"
    @reset-mutation-model="resetMutationModel()"
    :update-validation="validation"
    :update="() => new Promise<undefined>()"
    v-model:update-open="fakeUpdateOpen"
    :delete="doDelete"
    v-model:delete-item="deleteItem"
    delete-warning="If you delete the iCal import all imported events will stay."
  >
    <template #items-table>
      <v-alert type="info" title="Import are scheduled" icon="update" style="margin-bottom: 1em">
        The imports below run on a schedule. About <strong>every 30 minutes</strong> we check for
        changes. If you just added a url, it could take up to 30 minutes before you see the events.
      </v-alert>

      <item-table :headers="['Name', 'URL', 'Event type']" :items="items">
        <template #item="{ item }">
          <td>{{ item.name }}</td>
          <td>
            <a :href="item.url" target="_blank" rel="noopener"><v-icon icon="link" /></a>
          </td>
          <td>{{ item.eventType.name }} ({{ item.descriptionFieldMeta.name }})</td>
          <td>
            <item-delete-icon @click="deleteItem = item" />
            <v-icon
              title="Delete all events created by this import"
              icon="delete_sweep"
              size="24"
              @click="deleteAllEventsUrl = item"
            />
            <v-icon
              title="See last logs of import runs"
              icon="sticky_note_2"
              @click="runViewerUrl = item"
            />
          </td>
        </template>
      </item-table>
    </template>
    <template #create-form>
      <text-field
        label="Name"
        required
        v-model="mutationModel.name"
        :validation="validation.name"
      />
      <text-field label="URL" required v-model="mutationModel.url" :validation="validation.url" />
      <select-field
        :options="eventTypeOptions"
        label="Event type"
        v-model="mutationModel.eventTypeId"
        :validation="validation.eventTypeId"
      />
      <select-field
        :options="descriptionFieldOptions"
        label="Field for description"
        v-model="mutationModel.descriptionFieldMetaId"
        :validation="validation.descriptionFieldMetaId"
      />
    </template>
  </item-page>

  <delete-all-events-dialog v-if="!!deleteAllEventsUrl" v-model="deleteAllEventsUrl" />
  <run-viewer v-if="runViewerUrl" v-model="runViewerUrl" />
</template>

<style scoped lang="scss"></style>
