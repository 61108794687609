<script setup lang="ts">
import { RouterView, useRoute } from 'vue-router'
import { computed, provide, ref, watch } from 'vue'
import MainNavigationDrawer from '@/components/layout/MainNavigationDrawer.vue'
import MainAppBar from '@/components/layout/MainAppBar.vue'
import { keyOrganizationId, keyProjectId, keyUserInfo, Tracker } from '@/app'
import LoginOverlay from '@/components/layout/LoginOverlay.vue'
import { useAuth0 } from '@auth0/auth0-vue'
import * as Sentry from '@sentry/vue'
import UserInfo from '@/components/layout/UserInfo.vue'
import type { UserInfo as UserInfoType } from '@/generated/graphql'

const navbarOpen = ref(false)
const route = useRoute()

const orgId = computed(() => (route.params?.organizationId as string) || '')
provide(keyOrganizationId, orgId)
const projectId = computed(() => (route.params?.projectId as string) || '')
provide(keyProjectId, projectId)

const $auth0 = useAuth0()
const user = ref<UserInfoType>()
provide(keyUserInfo, user)
watch(
  user,
  (v?: UserInfoType) => {
    if (!v) {
      return
    }

    Tracker.track('login', { userId: v.id })
    Sentry.setUser({ id: v.id })
  },
  { immediate: true }
)
</script>

<template>
  <v-layout class="main-layout">
    <login-overlay />
    <user-info @user="(v) => (user = v)" />
    <main-app-bar @toggleDrawer="navbarOpen = !navbarOpen" />
    <main-navigation-drawer v-model="navbarOpen" />

    <v-main>
      <div class="router-view">
        <RouterView />
      </div>
    </v-main>
  </v-layout>
</template>

<style>
html,
body,
.router-view {
  height: 100%;
  min-height: 100%;
}
.router-view {
  height: calc(100vh - 144px);
  margin: 20px;
}

h1 {
  font-size: 40px;
  margin-bottom: 15px;
}
a,
a:visited,
a:active,
a:hover {
  color: rgb(var(--v-theme-primary));
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
</style>
