<script setup lang="ts">
import type {
  AttributeMeta,
  FileAttribute,
  FileAttributeFile,
  SetFileAttributesOnEventCommand,
  UnsetAttributeCommand
} from '@/generated/graphql'
import { inject, reactive, ref, watch } from 'vue'
import type { AttributeConfiguration } from '@/components/input/AttributeConfigurationField.vue'
import FormField from '@/components/input/FormField.vue'
import { helpers, maxLength, required } from '@vuelidate/validators'
import ValidationErrors from '@/components/input/ValidationErrors.vue'
import { ValidateEach } from '@vuelidate/components'
import { keyEventDuplication } from '@/components/events/shared'

const props = defineProps<{
  eventId: string
  meta: AttributeMeta
  seed?: FileAttribute
}>()
const emit = defineEmits<{
  command: [value: SetFileAttributesOnEventCommand | UnsetAttributeCommand]
}>()

type MutationModel = {
  attributeId: string
  eventId: string
  files: FileMutationModel[]
}
type FileMutationModel = {
  id?: string
  content?: string
  name?: string
  type?: string
  copyFrom?: string
}
const fileMutationModelRules = {
  name: { required },
  type: { required },
  content: {
    maxLength: helpers.withMessage('Files have to be smaller than 5MB', maxLength(7_077_888))
  }
}
const initialState: MutationModel = {
  attributeId: props.meta.id,
  eventId: props.eventId,
  files: []
}
const mutationModel = reactive<MutationModel>({ ...initialState })
const isDuplication = inject(keyEventDuplication)!
watch(
  () => props.seed,
  () => {
    if (!props.seed?.files) {
      return
    }

    mutationModel.files = props.seed.files.map((f: FileAttributeFile) => ({
      id: isDuplication.value ? undefined : f.id,
      name: f.name,
      type: f.type,
      copyFrom: isDuplication.value ? f.id : undefined
    }))
  },
  { immediate: true }
)
watch(mutationModel, () => emit('command', mutationModel), { immediate: isDuplication.value })

const selectedFiles = ref<File[]>()
async function uploadAll() {
  const files = selectedFiles.value || []
  files.forEach((f: File) => upload(f))
}
async function upload(file: File) {
  const reader = new FileReader()
  reader.onprogress = (e) => {}
  reader.onload = () => {
    const [metadata, content] = (reader.result as string).split(';')
    const [encoding, contentInBase64] = content.split(',')
    const [data, type] = metadata.split(':')

    mutationModel.files.push({
      name: file.name,
      type: type,
      content: contentInBase64
    })
    selectedFiles.value = selectedFiles.value?.filter((f: File) => f !== file)
  }
  reader.readAsDataURL(file)
}
</script>

<template>
  <form-field :label="props.meta.name">
    <v-file-input hide-details clearable multiple chips counter show-size v-model="selectedFiles">
      <template v-slot:append>
        <v-btn
          icon="upload"
          @click="uploadAll"
          :color="selectedFiles?.length || 0 > 0 ? 'primary' : 'white'"
        />
      </template>
    </v-file-input>
    <v-list>
      <validate-each
        v-for="(f, i) in mutationModel.files"
        :state="f"
        :rules="fileMutationModelRules"
        :key="i"
      >
        <template #default="{ v }">
          <v-list-item>
            <template v-slot:prepend>
              <v-icon v-if="f.content" icon="cloud_upload" />
              <v-icon v-if="!f.content" icon="cloud_done" />
            </template>
            <v-text-field hide-details v-model="f.name" @blur="v.$touch()" />
            <validation-errors :validation="v" />
            <template v-slot:append>
              <v-icon
                icon="delete"
                @click="mutationModel.files = mutationModel.files.filter((mm) => mm !== f)"
              />
            </template>
          </v-list-item>
        </template>
      </validate-each>
    </v-list>
  </form-field>
</template>

<style scoped lang="scss"></style>
