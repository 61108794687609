<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue'
import Checkbox from '@/components/input/Checkbox.vue'
import type { AttributeType } from '@/generated/graphql'

const props = defineProps<{
  modelValue?: string
  type: AttributeType
}>()
const emit = defineEmits<{
  'update:modelValue': [value: string]
}>()

export type AttributeConfiguration = {
  isMultiline?: boolean
  min?: number
  max?: number
  isMultiChoice?: boolean
  choices?: string[]
}
const initialState: AttributeConfiguration = {
  isMultiline: undefined,
  min: undefined,
  max: undefined,
  isMultiChoice: undefined,
  choices: undefined
}
const isMutationModelInitiated = ref(false)
const mutationModel = reactive<AttributeConfiguration>({ ...initialState })
function emitModel(model: AttributeConfiguration, keys: string[]) {
  let emitted: AttributeConfiguration = {}
  keys.forEach((k: string) => {
    emitted[k] = model[k]
  })
  emit('update:modelValue', JSON.stringify(emitted))
}

const initModel = computed(() => JSON.parse(props.modelValue || '{}') as AttributeConfiguration)
watch(
  initModel,
  () => {
    Object.keys(initialState).forEach((k: string) => {
      mutationModel[k] = initModel.value[k]
    })
    isMutationModelInitiated.value = true
  },
  { immediate: true }
)
watch(
  () => props.type,
  () => {
    Object.keys(initialState).forEach((k: string) => {
      mutationModel[k] = undefined
    })
  }
)

watch(mutationModel, (value: AttributeConfiguration) => {
  if (!isMutationModelInitiated.value) {
    return
  }

  switch (props.type) {
    case 'CHOICE':
      return emitModel(value, ['choices', 'isMultiChoice'])
    case 'INTEGER':
      return emitModel(value, ['min', 'max'])
    case 'RESOURCE':
      return emitModel(value, ['isMultiChoice'])
    case 'TEXT':
      return emitModel(value, ['isMultiline'])
  }
})

const choicesText = ref<string>()
watch(choicesText, (value: string) => {
  mutationModel.choices = value.split(/\r?\n/).filter((e) => e)
})
watch(
  () => mutationModel.choices,
  () => {
    const newText = mutationModel.choices?.join('\n')
    if (choicesText.value === newText) {
      return
    }

    choicesText.value = newText
  }
)
</script>

<template>
  <div>
    <template v-if="type === 'TEXT'">
      <checkbox label="Multiple lines" v-model="mutationModel.isMultiline" />
    </template>
    <template v-if="type === 'RESOURCE'">
      <checkbox label="Multiple resources can be selected" v-model="mutationModel.isMultiChoice" />
    </template>
    <template v-if="type === 'CHOICE'">
      <v-textarea label="Choices (one per line)" v-model="choicesText" />
      <checkbox label="Multiple choices can be selected" v-model="mutationModel.isMultiChoice" />
    </template>
  </div>
</template>
