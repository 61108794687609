<script lang="ts" setup>
import { computed, inject } from 'vue'
import { itemSort, keyOrganizationId, luxonToIsoOptions } from '@/app'
import { useQuery } from '@vue/apollo-composable'
import type { Event, EventSearchQuery, EventType, Organization, Query } from '@/generated/graphql'
import { Project } from '@/generated/graphql'
import gql from 'graphql-tag'
import { DateTime } from 'luxon'
import { useRouter } from 'vue-router'

const orgId = inject(keyOrganizationId)
const fetchQueryArgs: EventSearchQuery = {
  startBefore: DateTime.now().plus({ month: 1 }).startOf('month').toISO(luxonToIsoOptions),
  startAfter: DateTime.now().startOf('month').toISO(luxonToIsoOptions)
}
const fetchQuery = useQuery<Query>(
  gql`
    query getOrganizationStats($orgId: ID!, $searchQuery: EventSearchQuery!) {
      organization(id: $orgId) {
        id
        name
        projects {
          id
          name
          eventTypes {
            id
            name
          }
          events(query: $searchQuery) {
            id
            type {
              id
            }
          }
        }
      }
    }
  `,
  () => ({
    orgId: orgId?.value,
    searchQuery: fetchQueryArgs
  })
)
const organization = computed<Organization | undefined>(() => fetchQuery.result.value?.organization)
const projects = computed<Project[]>(() =>
  itemSort(organization.value?.projects || [], (p: Project) => p.name).map((p: Project) => ({
    ...p,
    eventTypes: itemSort(p.eventTypes, (et: EventType) => et.name)
  }))
)
const router = useRouter()
</script>

<template>
  <h1>{{ organization?.name }}</h1>

  <v-row>
    <v-col
      sm="6"
      md="3"
      xl="2"
      xxl="1"
      v-for="p in projects"
      :key="p.id"
      class="organization-project-overview"
    >
      <v-card>
        <v-card-title>{{ p.name }}</v-card-title>
        <v-card-text>
          <v-row dense class="total-count">
            <v-col cols="10">This month's events</v-col>
            <v-col>{{ p.events.length }}</v-col>
          </v-row>
          <v-row v-for="et in p.eventTypes" :key="et.id" dense class="events">
            <v-col cols="10">{{ et.name }}</v-col>
            <v-col>{{ p.events.filter((e: Event) => e.type.id == et.id).length }}</v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-icon
            color="primary"
            title="Go to calendar"
            icon="calendar_month"
            @click="router.push({ name: 'calendar', params: { projectId: p.id } })"
          />
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<style lang="scss">
.organization-project-overview {
  .total-count {
    font-weight: bold;
  }
  .events {
    font-size: 0.9em;
  }
}
</style>
