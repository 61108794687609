<script setup lang="ts">
import { computed, inject, reactive, ref } from 'vue'
import { itemSort, keyOrganizationId, keyProjectId } from '@/app'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import type { EventType, Query } from '@/generated/graphql'
import useVuelidate from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import TextField from '@/components/input/TextField.vue'
import ColorPicker from '@/components/input/ColorPicker.vue'
import IconPicker from '@/components/input/IconPicker.vue'
import Checkbox from '@/components/input/Checkbox.vue'
import ItemTable from '@/components/items/ItemTable.vue'
import ItemPage from '@/components/items/ItemPage.vue'
import { useRouter } from 'vue-router'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import ItemDeleteIcon from '@/components/items/ItemDeleteIcon.vue'
import ItemEditIcon from '@/components/items/ItemEditIcon.vue'

const orgId = inject(keyOrganizationId)!
const projectId = inject(keyProjectId)!
const fetchQuery = useQuery<Query>(
  gql`
    query getEventTypes($orgId: ID!, $projectId: ID!) {
      organization(id: $orgId) {
        id
        project(id: $projectId) {
          id
          eventTypes(withArchived: true) {
            id
            archived
            canDelete
            color
            icon
            name
            visibleInApp
          }
        }
      }
    }
  `,
  () => ({
    orgId: orgId.value,
    projectId: projectId.value
  })
)
const items = computed(() =>
  itemSort<EventType>(fetchQuery.result.value?.organization.project.eventTypes, (i) => i.name)
)

type MutationModel = {
  id?: string
  color?: string
  icon?: string
  name?: string
  visibleInApp?: boolean
  archived?: boolean
}
const initialState = {
  id: undefined,
  color: undefined,
  icon: undefined,
  name: undefined,
  visibleInApp: true,
  archived: undefined
}
const mutationModel = reactive<MutationModel>({ ...initialState })
function resetMutationModel() {
  Object.assign(mutationModel, initialState)
}
const validation = useVuelidate<MutationModel>(
  {
    color: { required },
    icon: { required },
    name: { required, min: minLength(3) }
  },
  mutationModel
)

const createMutation = useMutation(
  gql`
    mutation createEventType($orgId: ID!, $projectId: ID!, $command: CreateEventTypeCommand!) {
      organization(id: $orgId) {
        project(id: $projectId) {
          events {
            createEventType(input: $command) {
              id
            }
          }
        }
      }
    }
  `
)
async function create() {
  return createMutation.mutate({
    command: mutationModel,
    orgId: orgId.value,
    projectId: projectId.value
  })
}

const updateOpen = ref(false)
function updateItem(item: EventType) {
  Object.keys(initialState).forEach((k: string) => {
    mutationModel[k] = item[k]
  })
  updateOpen.value = true
}
const updateMutation = useMutation(
  gql`
    mutation updateEventType($orgId: ID!, $projectId: ID!, $command: UpdateEventTypeCommand!) {
      organization(id: $orgId) {
        project(id: $projectId) {
          events {
            updateEventType(input: $command) {
              id
            }
          }
        }
      }
    }
  `,
  () => ({
    refetchQueries: ['getEventTypesList']
  })
)

async function update() {
  return updateMutation.mutate({
    command: mutationModel,
    orgId: orgId.value,
    projectId: projectId.value
  })
}

const deleteItem = ref<EventType>()
const deleteMutation = useMutation(
  gql`
    mutation deleteEventType($orgId: ID!, $projectId: ID!, $id: ID!) {
      organization(id: $orgId) {
        project(id: $projectId) {
          events {
            deleteEventType(input: { id: $id }) {
              id
            }
          }
        }
      }
    }
  `,
  () => ({
    refetchQueries: ['getEventTypesList']
  })
)
function doDelete(id: string) {
  return deleteMutation.mutate({ id, orgId: orgId.value, projectId: projectId.value })
}

const notificationDuplicated = ref(false)
const duplicateMutation = useMutation(
  gql`
    mutation duplicateEventType($orgId: ID!, $projectId: ID!, $id: ID!) {
      organization(id: $orgId) {
        project(id: $projectId) {
          events {
            duplicateEventType(input: { fromId: $id }) {
              id
            }
          }
        }
      }
    }
  `,
  () => ({
    refetchQueries: ['getEventTypesList']
  })
)
const duplicateItem = ref<EventType>()
function duplicate() {
  duplicateMutation
    .mutate({
      orgId: orgId.value,
      projectId: projectId.value,
      id: duplicateItem.value?.id
    })
    .then(() => {
      notificationDuplicated.value = true
      fetchQuery.refetch()
    })
}

const router = useRouter()
function goToEventTypeAttributes(id: string) {
  router.push({ name: 'event-type-attributes', params: { eventTypeId: id } })
}
</script>

<template>
  <item-page
    title="Event types"
    :fetch="fetchQuery.refetch"
    :create-validation="validation"
    :create="create"
    @reset-mutation-model="resetMutationModel()"
    :update-validation="validation"
    :update="update"
    v-model:update-open="updateOpen"
    :delete="doDelete"
    v-model:delete-item="deleteItem"
  >
    <template #items-table>
      <item-table :headers="['Name']" :items="items">
        <template #item="{ item }">
          <td>{{ item.name }}</td>
          <td>
            <v-icon
              icon="list"
              title="Event type fields"
              @click="goToEventTypeAttributes(item.id)"
            />
            <v-icon
              icon="content_copy"
              title="Duplicate event type"
              @click="duplicateItem = item"
            />
            <item-edit-icon @click="updateItem(item)" />
            <item-delete-icon
              :disable-reason="
                item.canDelete
                  ? undefined
                  : 'Only when there are no events of this event type, it can be deleted'
              "
              @click="deleteItem = item"
            />
          </td>
        </template>
      </item-table>
    </template>
    <template #create-form>
      <text-field
        label="Name"
        required
        v-model="mutationModel.name"
        :validation="validation.name"
      />
      <checkbox label="Visible in calendar app" v-model="mutationModel.visibleInApp" />
      <color-picker
        label="Color"
        required
        v-model="mutationModel.color"
        :validation="validation.color"
      />
      <icon-picker
        label="Icon"
        required
        v-model="mutationModel.icon"
        :validation="validation.icon"
      />
    </template>
    <template #update-form>
      <text-field
        label="Name"
        required
        v-model="mutationModel.name"
        :validation="validation.name"
      />
      <checkbox label="Visible in calendar app" v-model="mutationModel.visibleInApp" />
      <checkbox label="Archived" required v-model="mutationModel.archived" />
      <color-picker
        label="Color"
        required
        v-model="mutationModel.color"
        :validation="validation.color"
      />
      <icon-picker
        label="Icon"
        required
        v-model="mutationModel.icon"
        :validation="validation.icon"
      />
    </template>
  </item-page>

  <confirm-dialog
    v-if="!!duplicateItem"
    title="Duplicate event type"
    :content="`Do you want to duplicate ${duplicateItem.name}`"
    :model-value="!!duplicateItem"
    @update:modelValue="duplicateItem = undefined"
    @ok="duplicate"
  />
  <notification-duplicated v-model="notificationDuplicated" />
</template>

<style scoped lang="scss"></style>
