<script setup lang="ts" generic="ITEM, MM">
import { computed, ref } from 'vue'
import { authzCanWrite, SimpleItem } from '@/app'
import { Validation, ValidationArgs } from '@vuelidate/core'
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue'
import EditDialog from '@/components/dialogs/EditDialog.vue'
import NotificationFailed from '@/components/notifications/NotificationFailed.vue'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'

const props = defineProps<{
  title: string
  createValidation: Validation<ValidationArgs, MM>
  updateValidation: Validation<ValidationArgs, MM>
  updateOpen: boolean
  deleteItem: SimpleItem | undefined
  fetch: () => void
  create: () => Promise<unknown>
  update: () => Promise<unknown>
  delete: (id: string) => Promise<unknown>
  deleteWarning?: string
}>()
const emit = defineEmits<{
  'update:updateOpen': [value: boolean]
  'update:deleteItem': [value: SimpleItem | undefined]
  created: []
  updated: []
  deleted: []
  resetMutationModel: []
}>()

const createValidation = computed(() => props.createValidation)
const updateValidation = computed(() => props.updateValidation)
const notificationFail = ref(false)

const createOpen = ref(false)
const notificationCreated = ref(false)
async function create() {
  await props
    .create()
    .then(() => {
      createOpen.value = false
      notificationCreated.value = true
      emit('created')
      props.fetch()
    })
    .catch((reason: unknown) => {
      notificationFail.value = true
      props.fetch()
      console.log('Could not create item', reason)
      throw new Error(`"Could not create item: ${reason}`)
    })
}
function resetCreate() {
  emit('resetMutationModel')
  props.createValidation.$reset()
}

const notificationUpdated = ref(false)
async function update() {
  props
    .update()
    .then(() => {
      notificationUpdated.value = true
      emit('update:updateOpen', false)
      emit('updated')
      props.fetch()
    })
    .catch((reason: unknown) => {
      notificationFail.value = true
      props.fetch()
      console.log('Could not update item', reason)
      throw new Error(`"Could not update item: ${reason}`)
    })
}

const notificationDeleted = ref(false)
function doDelete(id: string) {
  props
    .delete(id)
    .then(() => {
      notificationDeleted.value = true
      emit('update:deleteItem', undefined)
      emit('deleted')
      props.fetch()
    })
    .catch((reason: unknown) => {
      notificationFail.value = true
      props.fetch()
      console.log('Could not delete item', reason)
      throw new Error(`"Could not delete item: ${reason}`)
    })
}
</script>
<template>
  <div class="title-container">
    <h1>{{ props.title }}</h1>
    <v-btn
      v-if="authzCanWrite"
      rounded="lg"
      elevation="4"
      color="primary"
      class="create-button"
      @click="createOpen = true"
    >
      Create
    </v-btn>
  </div>

  <slot name="items-table" />

  <notification-failed v-model="notificationFail" />

  <delete-dialog
    v-if="props.deleteItem"
    :item="props.deleteItem"
    :model-value="!!props.deleteItem"
    @update:modelValue="emit('update:deleteItem', undefined)"
    @delete="doDelete"
    :warning="props.deleteWarning"
  />
  <notification-deleted v-model="notificationDeleted" />

  <edit-dialog
    v-model="createOpen"
    create
    :validation="createValidation"
    @save="create"
    @open="resetCreate()"
  >
    <slot name="create-form" />
  </edit-dialog>
  <notification-saved v-model="notificationCreated" />

  <edit-dialog
    :model-value="props.updateOpen"
    @update:modelValue="(v) => $emit('update:updateOpen', v)"
    :validation="updateValidation"
    @save="update"
  >
    <slot name="update-form" />
  </edit-dialog>
  <notification-saved v-model="notificationUpdated" />
</template>

<style scoped lang="scss">
.title-container {
  position: relative;

  .create-button {
    position: absolute;
    top: 11px;
    right: 0;
  }
}
</style>
