import {createApp, h, provide} from 'vue'
import App from './App.vue'
import router from './router'
import 'vuetify/styles'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as labsComponents from 'vuetify/labs/components'
import * as directives from 'vuetify/directives'
import {apolloClient} from '@/apollo'
import {DefaultApolloClient} from '@vue/apollo-composable'
import {customTheme} from '@/theme'
import {aliases, md} from 'vuetify/iconsets/md'
import VCalendar from 'v-calendar'
import 'v-calendar/style.css'
import '@/assets/main.css'
import {createAuth0} from '@auth0/auth0-vue'
import * as Sentry from '@sentry/vue'
import {HttpClient as HttpClientIntegration} from "@sentry/integrations";
import VueGtag from "vue-gtag";

// Vuetify
const vuetify = createVuetify({
  components: {
    ...components,
    ...labsComponents
  },
  directives,
  theme: {
    defaultTheme: 'customTheme',
    themes: {
      customTheme
    }
  },
  icons: {
    defaultSet: 'md',
    aliases,
    sets: {
      md
    }
  }
})

// Auth0
const auth0 = createAuth0(
  {
    domain: import.meta.env.VITE_AUTH0_DOMAIN as string,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID as string,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: "https://calena-app.eu.auth0.com/api/v2/",
    },
  },
  {
    skipRedirectCallback: window.location.pathname === '/oauth/google-sheets'
  }
)

// App init
const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App)
})

// Sentry
const environment = import.meta.env.VITE_ENVIRONMENT as string
if (environment != 'development') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN as string,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', 'console.calena.app', 'v3.console.calena.app']
      }),
      new HttpClientIntegration({
        failedRequestStatusCodes: [[400, 599]]
      })
    ],
    environment,
    release: import.meta.env.VITE_GIT_SHA as string,
    autoSessionTracking: true,
    tracesSampleRate: 0.5,
    logErrors: true,
    attachProps: true,
    attachStacktrace: true
  })
}

// App run
app.use(router)
app.use(vuetify)
app.use(auth0)
app.use(VCalendar, {})
app.use(VueGtag, {config: { id: "G-BNDXF0QTKD" }}, router)

app.mount('#app')
