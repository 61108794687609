import type { InjectionKey, Ref } from 'vue'
import { computed, inject } from 'vue'
import { DateTime, ToISOTimeOptions } from 'luxon'
import { event } from 'vue-gtag'
import type { UserAuthorization, UserInfo } from '@/generated/graphql'

export const keyOrganizationId: InjectionKey<Ref<string>> = Symbol('Organization ID')
export const keyProjectId: InjectionKey<Ref<string>> = Symbol('Project ID')
export const keyUserInfo: InjectionKey<Ref<UserInfo>> = Symbol('User Info')

export interface SimpleItem {
  id: string
  name: string
}

export function itemSort<T>(items: T[] | undefined, extractor: (item: T) => string): T[] {
  return (
    items
      ?.map((i) => JSON.parse(JSON.stringify(i)))
      ?.sort((a: T, b: T) => extractor(a).localeCompare(extractor(b))) || []
  )
}

export function toDate(date?: string, withWeekDay?: boolean) {
  return date ? DateTime.fromISO(date).toFormat(`${withWeekDay ? 'ccc ' : ''}d LLL `) : ''
}

export function toTime(date?: string) {
  return date ? DateTime.fromISO(date).toFormat('H:mm') : ''
}

export function toDateTime(date?: string) {
  return date ? toDate(date) + ' ' + toTime(date) : ''
}

export const luxonToIsoOptions: ToISOTimeOptions = {
  includeOffset: false,
  suppressMilliseconds: true
}

export const Tracker = {
  track(action: string, params: object) {
    const projectId = inject(keyProjectId)?.value
    const organizationId = inject(keyOrganizationId)?.value
    event(action, { projectId, organizationId, ...params })
  }
}

const authorizations = computed<UserAuthorization | undefined>(() => {
  const organizationId = inject(keyOrganizationId)?.value
  const userInfo = inject(keyUserInfo)?.value

  if (!organizationId || !userInfo) {
    return undefined
  }

  return userInfo.authorizations.find((a: UserAuthorization) => a.orgId == organizationId)
})
export const authzIsAdmin = computed<boolean>(() => authorizations.value?.isAdmin || false)

export const authzCanWrite = computed<boolean>(() => authorizations.value?.canWrite || false)
