<script setup lang="ts">
import { useMutation, useQuery } from '@vue/apollo-composable'
import type { EventType, Query } from '@/generated/graphql'
import gql from 'graphql-tag'
import { computed, inject, reactive, ref, Ref, watch } from 'vue'
import { itemSort, keyOrganizationId, keyProjectId, luxonToIsoOptions, toDate } from '@/app'
import InlineTimePicker from '@/components/input/InlineTimePicker.vue'
import TextField from '@/components/input/TextField.vue'
import { DateTime } from 'luxon'
import { v4 } from 'uuid'
import InlineTimezonePicker from '@/components/input/InlineTimezonePicker.vue'
import FormField from '@/components/input/FormField.vue'
import useVuelidate from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import NotificationFailed from '@/components/notifications/NotificationFailed.vue'

const props = defineProps<{
  modelValue: boolean
  date: string
}>()
const emit = defineEmits<{
  'update:modelValue': [value: boolean]
  created: []
}>()

const orgId = inject<Ref<string>>(keyOrganizationId)!
const projectId = inject<Ref<string>>(keyProjectId)!
const fetchQuery = useQuery<Query>(
  gql`
    query getEventTypesListForBatchEvents($orgId: ID!, $projectId: ID!) {
      organization(id: $orgId) {
        project(id: $projectId) {
          eventTypes {
            id
            name
            color
            icon
          }
        }
      }
    }
  `,
  () => ({
    orgId: orgId.value,
    projectId: projectId.value
  })
)
const types = computed<EventType[]>(() =>
  itemSort(fetchQuery.result.value?.organization.project.eventTypes || [], (e: EventType) => e.name)
)

type TypeOnDate = {
  id: string
  name: string
  color: string
  icon: string
  time: string
}

const batch = ref<TypeOnDate[]>([])
type MutationModel = {
  name: string
  timezone?: string
}
const mutationModel = reactive({
  namePrefix: undefined,
  timezone: undefined
})
const validation = useVuelidate<MutationModel>(
  {
    namePrefix: { required, min: minLength(3) },
    timezone: { required }
  },
  mutationModel
)
watch(
  () => props.modelValue,
  () => {
    if (props.modelValue) {
      mutationModel.namePrefix = undefined
      mutationModel.timezone = undefined
      batch.value = []
      validation.value.$reset()
    }
  }
)

type Batch = {
  id: string
  projectId: string
  name: string
  types: TypeOnDate[]
}

const previousBatches = ref<Batch[]>(loadPreviousBatches())
const projectBatches = computed<Batch[]>(() => {
  return previousBatches.value.filter((b: Batch) => b.projectId == projectId.value)
})
watch(
  previousBatches,
  (v: Batch[]) => {
    window.localStorage.setItem('batch-events', JSON.stringify(v))
  },
  { deep: true }
)
function loadPreviousBatches(): Batch[] {
  return itemSort(
    JSON.parse(window.localStorage.getItem('batch-events') || '[]'),
    (b: Batch) => b.name
  )
}
const batchName = ref<string>('')
function saveCurrentBatch() {
  const newValue = previousBatches.value.concat([
    {
      id: v4(),
      projectId: projectId.value,
      name: batchName.value,
      types: JSON.parse(JSON.stringify(batch.value))
    }
  ])
  previousBatches.value = itemSort(newValue, (b: Batch) => b.name)
}
function deletePreviousBatch(id: string) {
  previousBatches.value = previousBatches.value.filter((b: Batch) => b.id != id)
}

const mutateQuery = useMutation(gql`
  mutation createBatchEvent($orgId: ID!, $projectId: ID!, $command: CreateEventCommand!) {
    organization(id: $orgId) {
      project(id: $projectId) {
        events {
          createEvent(input: $command) {
            id
          }
        }
      }
    }
  }
`)

const isCreating = ref(false)
const failNotification = ref(false)
const successNotification = ref(false)
async function createEvents() {
  validation.value.$touch()
  if (validation.value.$error) {
    return
  }

  isCreating.value = true
  const mutationOptions = batch.value.map((t: TypeOnDate) => {
    const time = DateTime.fromISO(t.time)
    const start = DateTime.fromISO(props.date).set({
      hour: time.hour,
      minute: time.minute,
      second: 0,
      millisecond: 0
    })
    const end = start.plus({ hour: 1 })
    return {
      orgId: orgId?.value,
      projectId: projectId?.value,
      command: {
        name: `${mutationModel.namePrefix} ${t.name}`,
        typeId: t.id,
        start: start.toISO(luxonToIsoOptions),
        end: end.toISO(luxonToIsoOptions),
        startTimezone: mutationModel.timezone,
        endTimezone: mutationModel.timezone
      }
    }
  })
  for (const mutationOption of mutationOptions) {
    await mutateQuery.mutate(mutationOption).catch((reason: unknown) => {
      failNotification.value = true
      isCreating.value = false
      console.log('Could not create batch events', reason)
      throw new Error(`"Could not create batch events: ${reason}`)
    })
  }

  isCreating.value = false
  successNotification.value = true
  emit('created')
  emit('update:modelValue', false)
}
</script>

<template>
  <v-dialog
    :model-value="props.modelValue"
    @update:modelValue="(v) => emit('update:modelValue', v)"
    class="batch-events"
    max-width="1200"
    height="80%"
  >
    <v-card height="100%">
      <v-card-title
        >Create events in batch on <strong>{{ toDate(props.date) }}</strong></v-card-title
      >
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <h3>Add to batch</h3>
            <div
              v-for="type in types"
              :key="type.id"
              class="event-type"
              :style="`background-color: ${type.color}; cursor: pointer;`"
              @click="batch.push({ ...type, time: '10:00' })"
            >
              <v-icon :icon="type.icon" />
              {{ type.name }}
            </div>
          </v-col>

          <v-col cols="5">
            <h3>Current batch</h3>
            <text-field
              label="Name prefix"
              v-model="mutationModel.namePrefix"
              :validation="validation.namePrefix"
            />
            <form-field label="Timezone for all events" :validation="validation.timezone">
              <inline-timezone-picker
                v-model="mutationModel.timezone"
                :validation="validation.timezone"
              />
            </form-field>
            <div
              v-for="(type, i) in batch"
              :key="type.id"
              class="event-type"
              :style="`background-color: ${type.color}`"
            >
              <v-row align="center">
                <v-col cols="3">
                  <inline-time-picker v-model="type.time" density="compact" />
                </v-col>
                <v-col>
                  <v-icon :icon="type.icon" />
                  {{ type.name }}
                  <span style="position: absolute; right: 0">
                    <v-icon
                      v-if="i !== 0"
                      icon="north"
                      @click="batch[i] = batch.splice(i - 1, 1, type)[0]"
                    />
                    <v-icon
                      v-if="i !== batch.length - 1"
                      icon="south"
                      @click="batch[i] = batch.splice(i + 1, 1, type)[0]"
                    />

                    <v-icon icon="delete" @click="batch.splice(i, 1)" />
                  </span>
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col>
            <h3>Previous batches</h3>
            <text-field label="Save this batch as" v-model="batchName">
              <template #append>
                <v-btn @click="saveCurrentBatch()">Save</v-btn>
              </template>
            </text-field>

            <h4>Saved</h4>

            <ul>
              <v-list-item
                v-for="b in projectBatches"
                :key="b.id"
                style="cursor: pointer"
                @click="batch = JSON.parse(JSON.stringify(b.types))"
              >
                <span style="width: 50em">{{ b.name }}</span>
                <span style="position: absolute; right: 0">
                  <v-btn
                    icon="delete"
                    variant="flat"
                    density="compact"
                    @click="deletePreviousBatch(b.id)"
                  />
                </span>
              </v-list-item>
            </ul>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="emit('update:modelValue', false)">Cancel</v-btn>
        <v-btn @click="createEvents()" color="primary" :loading="isCreating" :disabled="isCreating"
          >Create events</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <notification-failed v-model="failNotification" />
  <v-snackbar v-model="successNotification" :timeout="3000">
    Created {{ batch.length }} events on {{ toDate(props.date) }}
  </v-snackbar>
</template>

<style scoped lang="scss">
h3 {
  margin-bottom: 1em;
}
.event-type {
  position: relative;
  border-radius: 3px;
  margin-bottom: 3px;
  padding: 8px 10px;
}
</style>
