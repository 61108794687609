<script setup lang="ts">
import { computed, inject, reactive, ref } from 'vue'
import { itemSort, keyOrganizationId } from '@/app'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import type { Authorization, Query, Resource } from '@/generated/graphql'
import useVuelidate from '@vuelidate/core'
import { email, minLength, required } from '@vuelidate/validators'
import TextField from '@/components/input/TextField.vue'
import ItemTable from '@/components/items/ItemTable.vue'
import ItemPage from '@/components/items/ItemPage.vue'
import ItemDeleteIcon from '@/components/items/ItemDeleteIcon.vue'
import ItemEditIcon from '@/components/items/ItemEditIcon.vue'
import Checkbox from '@/components/input/Checkbox.vue'

const orgId = inject(keyOrganizationId)!
const fetchQuery = useQuery<Query>(
  gql`
    query getUsers($orgId: ID!) {
      organization(id: $orgId) {
        authorizations {
          id
          userName
          isAdmin
          canRead
          canWrite
        }
      }
    }
  `,
  () => ({
    orgId: orgId.value
  })
)
const items = computed(() =>
  itemSort<Authorization>(fetchQuery.result.value?.organization.authorizations, (i) => i.userName)
)

type MutationModel = {
  isAdmin?: boolean
  canRead?: boolean
  canWrite?: boolean
}

type CreateMutationModel = {
  name?: string
  email?: string
}
const createInitialState: MutationModel & CreateMutationModel = {
  name: undefined,
  email: undefined,
  isAdmin: false,
  canRead: true,
  canWrite: false
}
const createMutationModel = reactive<CreateMutationModel & MutationModel>({ ...createInitialState })
function resetMutationModel() {
  Object.assign(createMutationModel, createInitialState)
}
const createValidation = useVuelidate<CreateMutationModel & MutationModel>(
  {
    email: { required, email },
    name: { required, min: minLength(3) }
  },
  createMutationModel
)

const createMutation = useMutation(
  gql`
    mutation inviteUser($orgId: ID!, $command: InviteUserToOrganizationCommand!) {
      organization(id: $orgId) {
        inviteUser(input: $command) {
          id
        }
      }
    }
  `
)
async function create() {
  return createMutation.mutate({
    command: createMutationModel,
    orgId: orgId.value
  })
}

type UpdateMutationModel = {
  id?: string
}
const updateOpen = ref(false)
const updateInitialState: MutationModel & UpdateMutationModel = {
  id: undefined,
  isAdmin: undefined,
  canRead: undefined,
  canWrite: undefined
}
const updateMutationModel = reactive<MutationModel & UpdateMutationModel>({ ...updateInitialState })
const updateValidation = useVuelidate<MutationModel & UpdateMutationModel>({}, updateMutationModel)
function updateItem(item: Authorization) {
  Object.keys(updateInitialState).forEach((k: string) => {
    updateMutationModel[k] = item[k]
  })
  updateOpen.value = true
}
const updateMutation = useMutation(
  gql`
    mutation updateAuthorization($orgId: ID!, $command: UpdateAuthorizationCommand!) {
      organization(id: $orgId) {
        updateAuthorization(input: $command) {
          id
        }
      }
    }
  `
)

async function update() {
  return updateMutation.mutate({
    orgId: orgId.value,
    command: updateMutationModel
  })
}

const deleteItem = ref<Resource>()
const deleteMutation = useMutation(
  gql`
    mutation deleteAuthorization($orgId: ID!, $id: ID!) {
      organization(id: $orgId) {
        deleteAuthorization(id: $id)
      }
    }
  `
)
function doDelete(id: string) {
  return deleteMutation.mutate({ id, orgId: orgId.value })
}
</script>

<template>
  <item-page
    title="Users and authorization"
    :fetch="fetchQuery.refetch"
    :create-validation="createValidation"
    :create="create"
    @reset-mutation-model="resetMutationModel()"
    :update-validation="updateValidation"
    :update="update"
    v-model:update-open="updateOpen"
    :delete="doDelete"
    v-model:delete-item="deleteItem"
  >
    <template #items-table>
      <item-table :headers="['Name']" :items="items">
        <template #item="{ item }">
          <td>{{ item.userName }}</td>
          <td>
            <item-edit-icon @click="updateItem(item)" />
            <item-delete-icon @click="deleteItem = item" />
          </td>
        </template>
      </item-table>
    </template>
    <template #create-form>
      <text-field
        label="Email of the user"
        required
        v-model="createMutationModel.email"
        :validation="createValidation.email"
      />
      <text-field
        label="Name of the user"
        required
        v-model="createMutationModel.name"
        :validation="createValidation.name"
      />
      <checkbox label="Is an administrator" v-model="createMutationModel.isAdmin" />
      <checkbox label="Can view resources & events" v-model="createMutationModel.canRead" />
      <checkbox label="Can change resources & events" v-model="createMutationModel.canWrite" />
    </template>
    <template #update-form>
      <checkbox label="Is an administrator" v-model="updateMutationModel.isAdmin" />
      <checkbox label="Can view resources & events" v-model="updateMutationModel.canRead" />
      <checkbox label="Can change resources & events" v-model="updateMutationModel.canWrite" />
    </template>
  </item-page>
</template>

<style scoped lang="scss"></style>
