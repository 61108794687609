<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue'
import { useQuery } from '@vue/apollo-composable'
import type { Query, UserInfo as UserInfoType } from '@/generated/graphql'
import { computed, watch } from 'vue'
import gql from 'graphql-tag'

const props = defineProps<{}>()
const emit = defineEmits<{
  user: [value: UserInfoType]
}>()

const auth0 = useAuth0()
const fetchQuery = useQuery<Query>(
  gql`
    query getUserInfo {
      userInfo {
        id
        authorizations {
          orgId
          canRead
          canWrite
          isAdmin
        }
      }
    }
  `,
  {},
  () => ({
    enabled: !!auth0.user.value?.email
  })
)
const userInfo = computed<UserInfoType | undefined>(() => fetchQuery.result.value?.userInfo)
watch(userInfo, (v?: UserInfoType) => {
  if (!v) {
    return
  }

  emit('user', v)
})
</script>

<template></template>

<style scoped lang="scss"></style>
