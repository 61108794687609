<script setup lang="ts">
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { Calendar, CalendarOptions } from '@fullcalendar/core'
import { computed, inject, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import type {
  AttributeUnion,
  Event,
  EventSearchQuery,
  NocturnalAttribute,
  Query
} from '@/generated/graphql'
import { SortDirection } from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { authzCanWrite, keyOrganizationId, keyProjectId, luxonToIsoOptions } from '@/app'
import { DateTime, DurationLike } from 'luxon'
import CreateEvent from '@/components/events/CreateEvent.vue'
import ViewEvent from '@/components/events/ViewEvent.vue'
import { EventImpl } from '@fullcalendar/core/internal'
import { useRoute, useRouter } from 'vue-router'
import Shortcuts from '@/components/events/Shortcuts.vue'
import MultiEventActions from '@/components/events/MultiEventActions.vue'
import BatchEvents from '@/components/events/BatchEvents.vue'

const route = useRoute()
const router = useRouter()
const orgId = inject(keyOrganizationId)
const projectId = inject(keyProjectId)

const fetchQueryArgs = reactive<EventSearchQuery>({
  startAfter: '',
  startBefore: '',
  sort: 'ASC' as SortDirection.Asc
})
const fetchQuery = useQuery<Query>(
  gql`
    query getCalendarEvents($orgId: ID!, $projectId: ID!, $query: EventSearchQuery!) {
      organization(id: $orgId) {
        id
        project(id: $projectId) {
          id
          eventTypes {
            id
            color
          }
          events(query: $query) {
            id
            start
            end
            name
            startTimezone
            endTimezone
            type {
              id
              name
              color
              icon
            }
            attributes {
              ... on NocturnalAttribute {
                active
              }
            }
          }
        }
      }
    }
  `,
  () => ({
    orgId: orgId?.value,
    projectId: projectId?.value,
    query: fetchQueryArgs
  }),
  () => ({
    enabled: !!fetchQueryArgs.startBefore
  })
)
const events = computed(() => {
  const events = fetchQuery.result.value?.organization.project.events || []
  return events.map((e: Event, index: number) => {
    const startAndEnd = calculateStartAndEndWithNocturnal(e, index)
    return {
      id: e.id,
      start: startAndEnd.start.toISO(luxonToIsoOptions),
      end: startAndEnd.end.toISO(luxonToIsoOptions),
      title: e.name,
      editable: false,
      extendedProps: {
        event: e,
        calculatedStart: startAndEnd.start,
        calculatedEnd: startAndEnd.end
      }
    }
  })
})
function calculateStartAndEndWithNocturnal(
  e: Event,
  index: number
): { start: DateTime; end: DateTime } {
  const halfDayInMinutes = 720
  const originalStart = DateTime.fromISO(e.start)
  const originalEnd = DateTime.fromISO(e.end)

  let start = originalStart.startOf('day').plus({ minutes: index + 1 })
  let end = originalEnd.startOf('day').plus({ minutes: index + 1 })

  const nocturnal = e.attributes.find(
    (a: AttributeUnion) => a.__typename == 'NocturnalAttribute'
  ) as NocturnalAttribute | undefined
  if (nocturnal && nocturnal.active) {
    start = start.minus({ minutes: halfDayInMinutes })
    end = end.minus({ minutes: halfDayInMinutes })
  }

  if (!originalEnd.hasSame(originalStart, 'day') && originalEnd.hour <= 8) {
    end = end.minus({ day: 1 })
  }

  return { start, end }
}

function exitEvent() {
  router.push({ name: 'calendar', params: { eventId: undefined } })
}

const calendar = ref<{ getApi: () => Calendar }>()
const selectedDate = ref<string>(DateTime.now().startOf('hour').toISO(luxonToIsoOptions))
function incrementDate(duration: DurationLike) {
  selectedDate.value = DateTime.fromISO(selectedDate.value).plus(duration).toISO(luxonToIsoOptions)
}
function toToday() {
  selectedDate.value = DateTime.now().toISO(luxonToIsoOptions)
}

const multiSelectActive = ref(false)
const multiSelectedEventIds = ref<string[]>([])
watch(multiSelectActive, () => {
  multiSelectedEventIds.value = []
})
const selectedEventId = ref<string>()
function selectEvent(id: string) {
  if (multiSelectActive.value) {
    if (multiSelectedEventIds.value.includes(id)) {
      multiSelectedEventIds.value = multiSelectedEventIds.value.filter((i: string) => i !== id)
      return
    }

    multiSelectedEventIds.value.push(id)
    return
  }
  router.push({ name: 'view-event', params: { eventId: id } })
}
watch(
  route,
  () => {
    selectedEventId.value = route.params.eventId
  },
  { immediate: true }
)
const calendarOptions: CalendarOptions = {
  plugins: [dayGridPlugin, interactionPlugin],
  headerToolbar: false,
  height: '96%',
  firstDay: 1,
  eventOverlap: true,
  datesSet: ({ start, end }: { start: Date; end: Date }) => {
    fetchQueryArgs.startAfter = DateTime.fromJSDate(start).toISO(luxonToIsoOptions)
    fetchQueryArgs.startBefore = DateTime.fromJSDate(end).toISO(luxonToIsoOptions)
  },
  events: function (_: unknown, successCallback: (event: EventImpl) => void) {
    successCallback(events.value)
  },
  dateClick: function (selectionInfo: any) {
    selectedDate.value = DateTime.fromJSDate(selectionInfo.date).toISO(luxonToIsoOptions)
  }
}
watch(events, () => {
  calendar.value?.getApi().refetchEvents()
})
watch(
  selectedDate,
  (value: string) => {
    calendar.value?.getApi().gotoDate(value)
    calendar.value?.getApi().select(value)
  },
  { immediate: true }
)

const currentMonth = computed<string>(() =>
  fetchQueryArgs.startAfter
    ? DateTime.fromISO(fetchQueryArgs.startAfter).plus({ week: 1 }).toFormat('LLLL yyyy')
    : ''
)

const createOpen = ref(false)
const duplicateEvent = ref<Event>()
function doDuplicate(event: Event) {
  duplicateEvent.value = event
  createOpen.value = true
}

const batchEventsOpen = ref(false)
const shortcutsOpen = ref(false)
function keyboardListener(event: KeyboardEvent) {
  if (!createOpen.value && !selectedEventId.value && !batchEventsOpen.value) {
    if (event.key == ' ' || event.key == 'Spacebar') {
      createOpen.value = true
      return
    }
    if (event.key == 'ArrowLeft') {
      event.ctrlKey || event.metaKey
        ? event.shiftKey
          ? incrementDate({ year: -1 })
          : incrementDate({ month: -1 })
        : incrementDate({ day: -1 })
      return
    }
    if (event.key == 'ArrowRight') {
      event.ctrlKey || event.metaKey
        ? event.shiftKey
          ? incrementDate({ year: 1 })
          : incrementDate({ month: 1 })
        : incrementDate({ day: 1 })
      return
    }
    if (event.key == 'ArrowUp') {
      incrementDate({ days: -7 })
      return
    }
    if (event.key == 'ArrowDown') {
      incrementDate({ days: 7 })
      return
    }
    if (event.key == 't') {
      selectedDate.value = DateTime.now().toISO(luxonToIsoOptions)
      return
    }
    if (event.key == 'm') {
      multiSelectActive.value = !multiSelectActive.value
      return
    }
    if (event.key == 'b') {
      batchEventsOpen.value = true
      return
    }
    if (event.key == '?' || event.key == '/') {
      shortcutsOpen.value = true
      return
    }
  }

  if (event.key == 'Escape' && !!selectedEventId.value) {
    exitEvent()
    return
  }
}
onMounted(() => window.addEventListener('keydown', keyboardListener))
onUnmounted(() => window.removeEventListener('keydown', keyboardListener))

function onRightClick(date: Date) {
  selectedDate.value = DateTime.fromJSDate(date)
    .startOf('second')
    .toISO(luxonToIsoOptions) as string
  createOpen.value = true
}

onMounted(() => {
  const calendarElement = document.getElementById('full-calendar')
  new ResizeObserver(() => calendar.value?.getApi().updateSize()).observe(calendarElement)
})
</script>

<template>
  <v-toolbar class="calendar-toolbar" id="calendar-toolbar" density="compact" color="background">
    <v-icon
      icon="keyboard_double_arrow_left"
      @click="incrementDate({ year: -1 })"
      title="Previous year (Ctrl/Cmd + Shift + ←)"
    />
    <v-icon
      icon="navigate_before"
      @click="incrementDate({ month: -1 })"
      title="Previous month (Ctrl/Cmd + ←)"
    />
    <v-icon icon="today" @click="toToday" title="Today (T)" />
    <v-icon
      icon="navigate_next"
      @click="incrementDate({ month: 1 })"
      title="Next month (Ctrl/Cmd + →)"
    />
    <v-icon
      icon="keyboard_double_arrow_right"
      @click="incrementDate({ year: 1 })"
      title="Next year (Ctrl/Cmd + Shift + →)"
    />

    <v-spacer />
    <span class="current-month">{{ currentMonth }}</span>

    <v-spacer />
    <v-icon
      v-if="!multiSelectActive && authzCanWrite"
      icon="check_box"
      @click="multiSelectActive = true"
      title="Select multiple events (M)"
    />
    <v-icon
      v-if="multiSelectActive"
      icon="indeterminate_check_box"
      @click="multiSelectActive = false"
      title="Disable multiselect (M)"
    />
    <v-icon
      v-if="authzCanWrite"
      icon="playlist_add"
      @click="batchEventsOpen = true"
      title="Create events in batch (B)"
    />
    <v-icon
      v-if="authzCanWrite"
      class="create-event-btn"
      icon="add"
      @click="createOpen = true"
      title="Create event (Spacebar)"
    />
  </v-toolbar>

  <full-calendar class="full-calendar" :options="calendarOptions" ref="calendar" id="full-calendar">
    <template #dayCellContent="context">
      <v-chip
        @click.right.stop.prevent="onRightClick(context.date)"
        density="compact"
        variant="text"
        style="cursor: context-menu"
        title="Right-click to create an event"
        :color="context.isToday ? 'primary' : undefined"
      >
        {{ context.dayNumberText }}
      </v-chip>
    </template>
    <template #eventContent="context">
      <template v-for="e in [context.event.extendedProps.event]" :key="e.id">
        <div
          class="event-content"
          :style="`background-color: ${e.type?.color};`"
          :title="e.name"
          @click="selectEvent(e.id)"
        >
          <div class="event-content-text">
            <v-checkbox-btn
              v-if="multiSelectActive"
              v-model="multiSelectedEventIds"
              :value="e.id"
              inline
              density="compact"
              class="multi-select-checkbox"
            />
            {{ DateTime.fromISO(e.start).toLocaleString(DateTime.TIME_24_SIMPLE) }}
            <v-icon :icon="e.type.icon" />
            {{ e.name }}
          </div>
        </div>
      </template>
    </template>
  </full-calendar>
  <v-progress-linear color="primary" indeterminate v-if="fetchQuery.loading.value" />

  <view-event
    v-if="!!selectedEventId"
    :id="selectedEventId"
    @refresh="fetchQuery.refetch()"
    @close="exitEvent()"
    @duplicate="(v) => doDuplicate(v)"
  />
  <create-event
    v-model="createOpen"
    :init-date="selectedDate"
    :seed="duplicateEvent"
    @duplicated="
      (v) => {
        selectedEventId = v
        duplicateEvent = undefined
      }
    "
  />
  <batch-events v-model="batchEventsOpen" :date="selectedDate" @created="fetchQuery.refetch()" />
  <multi-event-actions v-model="multiSelectedEventIds" @refresh="fetchQuery.refetch()" />
  <shortcuts v-model="shortcutsOpen" />
</template>

<style lang="scss">
.calendar-toolbar {
  margin-top: -0.7em;
}
.current-month {
  margin-left: 1em;
  font-weight: bold;
}

.fc {
  font-size: 0.95em;

  a,
  a:hover {
    color: rgb(var(--v-theme-on-surface));
  }
  .fc-h-event {
    border: 0;
  }
  .fc-event {
    overflow: hidden;
    margin: 2px 0 0 0;
    padding: 0;
    border-radius: 3px;

    .fc-event-main {
      color: rgb(var(--v-theme-on-surface));
    }

    .event-content {
      padding: 1px 3px;
      width: 100%;
      cursor: pointer;
    }
  }
  .fc-highlight {
    background-color: rgba(var(--v-theme-primary), 0.1);
  }
  .fc-daygrid-day.fc-day-today {
    background-color: #f8f8f8;
  }
  .multi-select-checkbox {
    .v-selection-control__wrapper,
    .v-selection-control__input {
      height: 9px;
      width: 20px;
    }
  }
}
</style>
