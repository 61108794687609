<script setup lang="ts">
import type {
  AttributeMeta,
  NocturnalAttribute,
  SetNocturnalAttributeOnEventCommand,
  SetToggleAttributeOnEventCommand,
  ToggleAttribute,
  UnsetAttributeCommand
} from '@/generated/graphql'
import { inject, reactive, watch } from 'vue'
import { v4 } from 'uuid'
import type { AttributeConfiguration } from '@/components/input/AttributeConfigurationField.vue'
import ToggleField from '@/components/input/ToggleField.vue'
import { keyEventDuplication } from '@/components/events/shared'

const props = defineProps<{
  eventId: string
  meta: AttributeMeta
  seed?: ToggleAttribute | NocturnalAttribute
}>()
const emit = defineEmits<{
  command: [
    value:
      | SetToggleAttributeOnEventCommand
      | SetNocturnalAttributeOnEventCommand
      | UnsetAttributeCommand
  ]
}>()

type MutationModel = {
  id?: string
  attributeId: string
  eventId: string
  active?: boolean
}
const initialState: MutationModel = {
  id: props.seed?.id || v4(),
  attributeId: props.meta.id,
  eventId: props.eventId,
  active: props.seed?.active
}
const mutationModel = reactive<MutationModel>({ ...initialState })
const unsetCommand: UnsetAttributeCommand = {
  eventId: props.eventId,
  attributeId: initialState.id!
}
const isDuplication = inject(keyEventDuplication)!
function isComplete(model: MutationModel) {
  return model.active !== undefined
}
watch(
  mutationModel,
  () => emit('command', isComplete(mutationModel) ? mutationModel : unsetCommand),
  { immediate: isDuplication.value }
)
</script>

<template>
  <div class="input-toggle-attribute">
    <toggle-field :label="props.meta.name" v-model="mutationModel.active" />
  </div>
</template>

<style lang="scss">
.input-toggle-attribute {
  .v-selection-control {
    min-height: auto;
  }
}
</style>
