<script setup lang="ts">
import { computed, inject, reactive, ref, watch } from 'vue'
import gql from 'graphql-tag'
import type {
  AttributeMeta,
  EventType,
  IntegrationTravelCaseResourceIdentifier,
  IntegrationTravelCaseResourceIdentifierCommand,
  Query,
  Resource
} from '@/generated/graphql'
import useVuelidate from '@vuelidate/core'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { itemSort, keyOrganizationId, keyProjectId } from '@/app'
import { required } from '@vuelidate/validators'
import SelectField from '@/components/input/SelectField.vue'
import TextField from '@/components/input/TextField.vue'
import NotificationFailed from '@/components/notifications/NotificationFailed.vue'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'

type MutationModel = {
  flightEventTypeId?: string
  resourceIdentifiers: IntegrationTravelCaseResourceIdentifierCommand[]
}
const initialState: MutationModel = {
  flightEventTypeId: undefined,
  resourceIdentifiers: []
}
const mutationModel = reactive<MutationModel>({ ...initialState })

const orgId = inject(keyOrganizationId)!
const projectId = inject(keyProjectId)!
const fetchQuery = useQuery<Query>(
  gql`
    query travelCaseIntegration($orgId: ID!, $projectId: ID!) {
      organization(id: $orgId) {
        id
        project(id: $projectId) {
          id
          integrations {
            travelCase {
              id
              flightEventType {
                id
              }
              resourceIdentifiers {
                resource {
                  id
                }
                crsName
              }
            }
          }
          eventTypes {
            id
            name
            attributeMetas {
              type
            }
          }
          resources {
            id
            name
          }
        }
      }
    }
  `,
  () => ({
    orgId: orgId.value,
    projectId: projectId.value
  })
)
const config = computed<TravelCase | undefined>(
  () => fetchQuery.result.value?.organization.project.integrations.travelCase
)
const eventTypes = computed(
  () =>
    itemSort<EventType>(
      fetchQuery.result.value?.organization.project.eventTypes || [],
      (t) => t.name
    )
      .filter(
        (t: EventType) =>
          t.attributeMetas.find((m: AttributeMeta) => m.type === 'FLIGHT') !== undefined
      )
      .map((t: EventType) => ({ value: t.id, title: t.name })) || []
)
const resources = computed<Resource[]>(
  () => fetchQuery.result.value?.organization.project.resources || []
)
watch(
  config,
  (v?: TravelCase) => {
    if (!v) {
      return
    }
    mutationModel.flightEventTypeId = v.flightEventType.id
    mutationModel.resourceIdentifiers = v.resourceIdentifiers.map(
      (ri: IntegrationTravelCaseResourceIdentifier) => ({
        crsName: ri.crsName,
        resourceId: ri.resource.id
      })
    )
  },
  { immediate: true }
)

const updateMutation = useMutation(
  gql`
    mutation updateTravelCase(
      $orgId: ID!
      $projectId: ID!
      $command: IntegrationTravelCaseConfigureCommand!
    ) {
      organization(id: $orgId) {
        project(id: $projectId) {
          integrations {
            configureTravelCase(input: $command) {
              id
              __typename
            }
          }
        }
      }
    }
  `
)

const validation = useVuelidate<MutationModel>(
  {
    flightEventTypeId: { required }
  },
  mutationModel
)
const saveNotification = ref(false)
const failNotification = ref(false)
function save() {
  updateMutation
    .mutate({
      orgId: orgId.value,
      projectId: projectId.value,
      command: mutationModel
    })
    .then(() => {
      saveNotification.value = true
      fetchQuery.refetch()
    })
    .catch((reason: unknown) => {
      failNotification.value = true
      console.log("Could not update Travel Case integration", reason)
      throw new Error(`"Could not update Travel Case integration: ${reason}`)
    })
}

const getCrsName = ref((resourceId: string) => {
  return mutationModel.resourceIdentifiers.find((ri) => ri.resourceId === resourceId)?.crsName
})
function setCrsName(resourceId: string, crsName?: string) {
  mutationModel.resourceIdentifiers = mutationModel.resourceIdentifiers.filter(
    (ri) => ri.resourceId !== resourceId
  )
  if (crsName) {
    mutationModel.resourceIdentifiers.push({
      resourceId: resourceId,
      crsName: crsName.trim()
    })
  }
}
</script>

<template>
  <h1>Travel Case Integration</h1>

  <p>
    With the <a href="http://www.travel-case.com/" target="_blank">Travel Case</a> integration we
    create calendar events form flights that are booked by your travel agent, freeing up your time
    to do other things.
  </p>

  <p>
    Travel Case connects with all major Global Distribution Systems like Amadeus, Galileo, Worldspan
    and Sabre, the same one your travel agent uses for their bookings. If your travel agent is also
    a customer of Travel Case, you can request them to forward the flights to Calena.
  </p>

  <p v-if="config?.id">
    When Travel Case sends us a booking, we need to couple it to this project. For that we use an
    integration ID. Your travel agent will ask you for it, they'll save it in the travelers profile
    under <code>Calena Project ID</code> on their end. The integration ID is:
    <code class="integration-id text-center">{{ config.id }}</code>
  </p>
  <p v-if="!config?.id">
    First fill out the form below to get further instructions on how to couple this project to your
    travel agent's booking process.
  </p>

  <v-row>
    <v-col cols="4">
      <h2>Event type</h2>

      <p>
        When Travel Case sends us a booking, we create an Event of a certain Even Type. What Event
        Type should that be? (Only Event Types that contain a field of type Flight are shown here)
      </p>
      <select-field
        v-if="eventTypes.length > 0"
        v-model="mutationModel.flightEventTypeId"
        :options="eventTypes"
        label="Flight field"
        required
        :validation="validation.flightEventType"
      />
      <v-alert v-if="eventTypes.length == 0" type="info">
        There are no event types in this project with a flight field
      </v-alert>
    </v-col>
    <v-col>
      <h2>CRS names</h2>
      <p>
        When we create an Event for a booking that Travel Case sends us, we can link resources to
        that event. Resources are linked based on their CRS names. Below you see all the resources
        in your project, please provide the CRS names for all the resources that you want to link
        automatically. If you're unfamiliar with CRS names, ask your booking agent and they can
        provide them to you.
      </p>

      <text-field
        v-for="r in resources"
        :key="r.id"
        :label="r.name"
        :model-value="getCrsName(r.id)"
        @update:modelValue="(v) => setCrsName(r.id, v)"
      />
      <v-alert v-if="resources.length == 0" type="info">
        There are no resources in this project
      </v-alert>
    </v-col>
  </v-row>
  <v-row>
    <v-col class="text-right">
      <v-btn color="primary" @click="save()">Save</v-btn>
      <notification-saved v-model="saveNotification" />
      <notification-failed v-model="failNotification" />
    </v-col>
  </v-row>
</template>

<style scoped lang="scss">
p {
  margin-bottom: 1em;
}
.integration-id {
  display: block;
  margin: 0.5em auto;
  font-size: 1.1em;
}
</style>
