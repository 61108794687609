<script setup lang="ts">
import type {
  AttributeMeta,
  Resource,
  ResourceAttribute,
  SetResourceAttributesOnEventCommand,
  UnsetAttributeCommand
} from '@/generated/graphql'
import { Query } from '@/generated/graphql'
import { computed, inject, reactive, watch } from 'vue'
import type { AttributeConfiguration } from '@/components/input/AttributeConfigurationField.vue'
import { parseConfig } from '@/components/events/attributes/shared'
import SelectField from '@/components/input/SelectField.vue'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { itemSort, keyOrganizationId, keyProjectId } from '@/app'
import { keyEventDuplication } from '@/components/events/shared'

const props = defineProps<{
  eventId: string
  meta: AttributeMeta
  seed?: ResourceAttribute
}>()
const emit = defineEmits<{
  command: [value: SetResourceAttributesOnEventCommand | UnsetAttributeCommand]
}>()

type MutationModel = {
  attributeId: string
  eventId: string
  resources?: string[]
}
const initialState: MutationModel = {
  attributeId: props.meta.id,
  eventId: props.eventId,
  resources: props.seed?.resources.map((r: Resource) => r.id)
}
const mutationModel = reactive<MutationModel>({ ...initialState })
const isDuplication = inject(keyEventDuplication)!
watch(mutationModel, () => emit('command', mutationModel), { immediate: isDuplication.value })

const orgId = inject(keyOrganizationId)!
const projectId = inject(keyProjectId)!
const fetchQuery = useQuery<Query>(
  gql`
    query getResourcesForAttribute($orgId: ID!, $projectId: ID!) {
      organization(id: $orgId) {
        project(id: $projectId) {
          resources {
            id
            name
            description
          }
        }
      }
    }
  `,
  () => ({
    orgId: orgId.value,
    projectId: projectId.value
  })
)
const resources = computed(() =>
  itemSort<Resource>(
    fetchQuery.result.value?.organization.project.resources || [],
    (i) => i.name
  ).map((r: Resource) => ({
    title: r.name,
    value: r.id
  }))
)
const config = parseConfig(props.meta.configuration)
</script>

<template>
  <select-field
    :options="resources"
    :label="props.meta.name"
    v-model="mutationModel.resources"
    :multiple="config?.isMultiChoice"
  />
</template>

<style scoped lang="scss"></style>
