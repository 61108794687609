import type { ComputedRef, Ref } from 'vue'
import { InjectionKey } from 'vue'
import useVuelidate, { Validation, ValidationArgs } from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import { Event } from '@/generated/graphql'

export type MutationModel = {
  id: string
  typeId?: string
  name?: string
  start?: string
  startTimezone?: string
  end?: string
  endTimezone?: string
}

export const keyEventStart: InjectionKey<ComputedRef<string>> = Symbol('Event start in ISO')
export const keyEvent: InjectionKey<Ref<Event | undefined>> = Symbol('Event')
export const keyEventDuplication: InjectionKey<Ref<boolean>> = Symbol('Event duplication')


export function createValidation<MM>(mutationModel: Ref<MutationModel>, withType: boolean)  : Ref<Validation<ValidationArgs, MM>> {
    const validationsArgs = {
        name: { required, min: minLength(3) },
        typeId: { },
        start: { required },
        startTimezone: { required },
        end: { required },
        endTimezone: { required }
    };
    if(withType) {
        validationsArgs.typeId = {required}
    }
    return useVuelidate<MutationModel>(validationsArgs, mutationModel
  )
}
