<script setup lang="ts">
import gql from 'graphql-tag'
import { inject, ref, watch } from 'vue'
import type { Mutation } from '@/generated/graphql'
import { useMutation } from '@vue/apollo-composable'
import { keyOrganizationId, keyProjectId } from '@/app'
import type { FetchResult } from '@apollo/client/core'
import GoogleSheetsOauthRequester from '@/components/integrations/google-sheets/GoogleSheetsOauthRequester.vue'

const props = defineProps<{
  modelValue: string[]
}>()
const emit = defineEmits<{
  loading: [value: boolean]
  url: [value?: string]
}>()

const orgId = inject(keyOrganizationId)!
const projectId = inject(keyProjectId)!
const exportMutation = useMutation<Mutation>(
  gql`
    mutation exportGoogleSheet($orgId: ID!, $projectId: ID!, $eventIds: [ID!]!) {
      organization(id: $orgId) {
        project(id: $projectId) {
          integrations {
            googleSheets {
              exportEvents(ids: $eventIds)
            }
          }
        }
      }
    }
  `
)

const authorized = ref(false)
const authorizationRefetch = ref(Math.random())
const failNotification = ref(false)
const exportedNotification = ref(false)
const url = ref<string>()

watch(
  () => [props.modelValue, authorized.value],
  async () => {
    if (props.modelValue.length == 0 || !authorized.value) {
      return
    }

    emit('loading', true)
    emit('url', undefined)
    exportMutation
      .mutate({
        orgId: orgId.value,
        projectId: projectId.value,
        eventIds: props.modelValue
      })
      .then((result: FetchResult) => {
        const v = result.data.organization.project.integrations.googleSheets.exportEvents
        emit('loading', false)
        emit('url', v)
        exportedNotification.value = true
        url.value = v
      })
      .catch((reason: unknown) => {
        emit('loading', false)
        emit('url', undefined)
        failNotification.value = true
        url.value = undefined
        console.log('Could not export to Google Sheet', reason)
        throw new Error(`"Could not export to Google Sheet: ${reason}`)
      })
  },
  { immediate: true }
)

function retry() {
  authorized.value = false
  failNotification.value = false
  authorizationRefetch.value = Math.random()
}
</script>

<template>
  <google-sheets-oauth-requester
    v-if="props.modelValue.length > 0"
    :refetch="authorizationRefetch"
    @authorized="authorized = true"
  />
  <v-snackbar :timeout="5000" v-model="exportedNotification">
    Exported events to <a :href="url" target="_blank" rel="noopener">Google Sheet</a>
  </v-snackbar>
  <v-snackbar :timeout="5000" v-model="failNotification">
    Could not export events to Google Sheet,
    <a @click="retry()">you want to retry?</a>
  </v-snackbar>
</template>
