import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import EmptyParentView from '@/views/EmptyParentView.vue'
import OrgHomeView from '@/views/organization/HomeView.vue'
import EventTypeView from '@/views/organization/project/EventTypeView.vue'
import CalendarView from '@/views/organization/project/CalendarView.vue'
import EventTypeAttributesView from '@/views/organization/project/EventTypeAttributesView.vue'
import ResourceView from '@/views/organization/project/ResourceView.vue'
import ProjectAccessTokenView from '@/views/organization/project/ProjectAccessTokenView.vue'
import ProjectView from '@/views/organization/ProjectView.vue'
import TravelCase from '@/views/organization/project/Integrations/TravelCaseView.vue'
import GoogleSheetsOAuthView from '@/views/oauth/GoogleSheetsOAuthView.vue'
import { authGuard } from '@auth0/auth0-vue'
import GoogleSheetsImportView from '@/views/organization/project/Integrations/GoogleSheetsImportView.vue'
import IcalImportView from '@/views/organization/project/Integrations/IcalImportView.vue'
import AuthorizationView from '@/views/organization/AuthorizationView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      name: 'oauth-google-sheets',
      path: '/oauth/google-sheets',
      component: GoogleSheetsOAuthView
    },
    {
      name: 'organization-parent',
      path: '/organization/:organizationId/',
      component: EmptyParentView,
      children: [
        {
          name: 'organization-home',
          path: '',
          component: OrgHomeView
        },
        {
          name: 'authorization',
          path: 'authorization',
          component: AuthorizationView
        },
        {
          name: 'projects',
          path: 'projects',
          component: ProjectView
        },
        {
          name: 'project-parent',
          path: 'project/:projectId/',
          component: EmptyParentView,
          children: [
            {
              name: 'resources',
              path: 'resources',
              component: ResourceView
            },
            {
              name: 'access-tokens',
              path: 'access-tokens',
              component: ProjectAccessTokenView
            },
            {
              name: 'event-types',
              path: 'event-types',
              component: EventTypeView
            },
            {
              name: 'event-type-attributes',
              path: 'event-types/:eventTypeId/fields',
              component: EventTypeAttributesView
            },
            {
              name: 'calendar',
              path: 'calendar',
              component: CalendarView,
              children: [
                {
                  name: 'view-event',
                  path: 'calendar/event/:eventId',
                  component: CalendarView
                }
              ]
            },
            {
              name: 'integration-travel-case',
              path: 'integration/travel-case',
              component: TravelCase
            },
            {
              name: 'integration-google-sheets-import',
              path: 'integration/google-sheets/import',
              component: GoogleSheetsImportView
            },
            {
              name: 'integration-ical-import',
              path: 'integration/ical-import',
              component: IcalImportView
            }
          ]
        }
      ]
    }
  ]
})
router.beforeEach(authGuard)

export default router
