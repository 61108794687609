<script setup lang="ts">
import { inject, reactive, ref, watch } from 'vue'
import { HeadlessService } from '@novu/headless'
import type { FetchResult } from '@apollo/client/core'
import type { ISession } from '@novu/headless/dist/utils/types'
import type { IMessage, IPaginatedResponse } from '@novu/shared'
import type { IMessageId, UpdateResult } from '@novu/headless/dist/lib/types'
import { keyUserInfo, toDateTime } from '../../app'
import type { UserInfo } from '@/generated/graphql'
import { useRouter } from 'vue-router'

export type Notifications = {
  unseen: number
  unread: number
}
const notifications = reactive<Notifications>({
  unseen: 0,
  unread: 0
})
const service = ref<HeadlessService>()
const user = inject(keyUserInfo)!

watch(
  user,
  (v?: UserInfo) => {
    if (!v) {
      return
    }

    const headless = new HeadlessService({
      applicationIdentifier: import.meta.env.VITE_NOVU_APP_ID as string,
      subscriberId: `user-${v.id}`
    })
    headless.initializeSession({
      listener: (res: FetchResult<ISession>) => {},
      onSuccess: (session: ISession) => {
        service.value = headless
      },
      onError: (error: unknown) => {
        console.error('Could not initialize notifications', error)
      }
    })
  },
  { immediate: true }
)
watch(service, (v?: HeadlessService) => {
  if (!v) {
    return
  }

  v.fetchUnreadCount({
    listener: (result: FetchResult<{ count: number }>) => {},
    onSuccess: (data: { count: number }) => {
      notifications.unread = data.count
    },
    onError: (error: unknown) => console.error('Could not fetch notification unread count', error)
  })
  v.fetchUnseenCount({
    listener: (result: FetchResult<{ count: number }>) => {},
    onSuccess: (data: { count: number }) => {
      notifications.unseen = data.count
    },
    onError: (error: unknown) => console.error('Could not fetch notification unseen count', error)
  })
  v.listenUnseenCountChange({
    listener: (unseenCount: number) => {
      notifications.unseen = unseenCount
    }
  })
  v.listenUnreadCountChange({
    listener: (unreadCount: number) => {
      notifications.unread = unreadCount
    }
  })
})

const messages = ref<IMessage[]>([])
const hasMore = ref(false)
const loading = ref(false)
const currentPage = ref(0)
function fetchMessages(page: number) {
  service.value?.fetchNotifications({
    listener: (result: FetchResult<IPaginatedResponse<IMessage>>) => {
      loading.value = result.isLoading
    },
    onSuccess: (response: IPaginatedResponse<IMessage>) => {
      messages.value = response.data
      hasMore.value = response.hasMore
      currentPage.value = response.page
      loading.value = false
    },
    onError: (error: unknown) => {
      console.log('Could not fetch notifications page', error)
      loading.value = false
    },
    page
  })
}
function markMessageRead(ids: string[]) {
  service.value?.markNotificationsAsRead({
    listener: (result: UpdateResult<IMessage, unknown, { messageId: IMessageId }>) => {},
    onSuccess: (message: IMessage) => {
      fetchMessages(currentPage.value)
    },
    onError: (error: unknown) => {
      console.error('Could not mark notification as read', error)
    },
    messageId: ids
  })
}
function deleteMessage(id: string) {
  service.value?.removeNotification({
    listener: (result: UpdateResult<IMessage, unknown, { messageId: string }>) => {},
    onSuccess: (message: IMessage) => {
      fetchMessages(currentPage.value)
    },
    onError: (error: unknown) => {
      console.error('Could not remove notification', error)
    },
    messageId: id
  })
}

const centerOpen = ref(false)
function markAllAsRead() {
  markMessageRead(messages.value.map((m) => m._id))
  centerOpen.value = false
}

const router = useRouter()
function goToOrganization(m: IMessage) {
  markMessageRead([m._id])
  router.push({
    name: 'organization-home',
    params: { organizationId: m.payload.organizationId }
  })
  centerOpen.value = false
}
function goToProject(m: IMessage) {
  markMessageRead([m._id])
  router.push({
    name: 'calendar',
    params: {
      organizationId: m.payload.organizationId,
      projectId: m.payload.projectId
    }
  })
  centerOpen.value = false
}
function goToEvent(m: IMessage) {
  markMessageRead([m._id])
  router.push({
    name: 'view-event',
    params: {
      organizationId: m.payload.organizationId,
      projectId: m.payload.projectId,
      eventId: m.payload.eventId
    }
  })
  centerOpen.value = false
}
</script>

<template>
  <v-menu
    width="500"
    :close-on-content-click="false"
    v-model="centerOpen"
    @update:modelValue="(v) => (v ? fetchMessages(0) : undefined)"
  >
    <template v-slot:activator="{ props }">
      <v-icon
        icon="mark_chat_read"
        v-if="notifications.unread == 0"
        class="notification-icon"
        v-bind="props"
      />
      <v-icon
        icon="mark_chat_unread"
        v-if="notifications.unread > 0"
        class="notification-icon"
        v-bind="props"
      >
      </v-icon>
    </template>
    <v-card>
      <v-card-text>
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
          style="position: absolute; right: 10px; z-index: 100"
        />
        <v-list>
          <v-list-item v-if="messages.length == 0">
            <v-list-item-subtitle>No notifications</v-list-item-subtitle>
          </v-list-item>
          <v-list-item v-for="m in messages" :key="m._id">
            <v-list-item-subtitle>
              <v-row>
                <v-col cols="7">
                  {{ toDateTime(m.createdAt) }}
                  <v-badge v-if="!m.read" color="primary" inline dot />
                </v-col>
                <v-col class="text-right"> </v-col>
              </v-row>
            </v-list-item-subtitle>
            {{ m.content }}
            <v-list-item-action>
              <v-spacer />
              <v-btn
                v-if="m.payload.organizationId"
                title="Go to organization"
                icon="login"
                variant="flat"
                density="compact"
                @click="goToOrganization(m)"
              />
              <v-btn
                v-if="m.payload.projectId && m.payload.organizationId"
                title="Go to project calendar"
                icon="calendar_month"
                variant="flat"
                density="compact"
                @click="goToProject(m)"
              />
              <v-btn
                v-if="m.payload.projectId && m.payload.organizationId && m.payload.eventId"
                title="Go to event"
                icon="calendar_today"
                variant="flat"
                density="compact"
                @click="goToEvent(m)"
              />
              <span style="display: inline-block; width: 1.5em" />
              <v-btn
                v-if="!m.read"
                title="Mark as read"
                icon="done"
                variant="flat"
                density="compact"
                @click="markMessageRead([m._id])"
              />
              <v-btn
                title="Delete"
                icon="delete"
                variant="flat"
                density="compact"
                @click="deleteMessage(m._id)"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions v-if="messages.length > 0">
        <v-btn v-if="currentPage > 0" icon="arrow_back" @click="fetchMessages(currentPage - 1)" />
        <v-btn v-if="hasMore" icon="arrow_forward" @click="fetchMessages(currentPage + 1)" />
        <v-spacer />
        <v-btn
          title="Mark all as read"
          icon="done_all"
          density="compact"
          @click="markAllAsRead()"
        />
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<style scoped lang="scss">
.notification-icon {
  color: #fff;
}
</style>
