
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "Attribute": [
      "AddressAttribute",
      "ChoiceAttribute",
      "FileAttribute",
      "FlightAttribute",
      "IntegerAttribute",
      "LinkAttribute",
      "NocturnalAttribute",
      "ResourceAttribute",
      "TextAttribute",
      "ToggleAttribute"
    ],
    "AttributeUnion": [
      "AddressAttribute",
      "ChoiceAttribute",
      "FileAttribute",
      "FlightAttribute",
      "IntegerAttribute",
      "LinkAttribute",
      "NocturnalAttribute",
      "ResourceAttribute",
      "TextAttribute",
      "ToggleAttribute"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Attribute": [
      "AddressAttribute",
      "ChoiceAttribute",
      "FileAttribute",
      "FlightAttribute",
      "IntegerAttribute",
      "LinkAttribute",
      "NocturnalAttribute",
      "ResourceAttribute",
      "TextAttribute",
      "ToggleAttribute"
    ],
    "AttributeUnion": [
      "AddressAttribute",
      "ChoiceAttribute",
      "FileAttribute",
      "FlightAttribute",
      "IntegerAttribute",
      "LinkAttribute",
      "NocturnalAttribute",
      "ResourceAttribute",
      "TextAttribute",
      "ToggleAttribute"
    ]
  }
};
      export default result;
    