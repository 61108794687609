<script setup lang="ts">
import type {
  AttributeMeta,
  CommercialFlight,
  FlightAttribute,
  Query,
  SetFlightAttributeOnEventCommand,
  UnsetAttributeCommand
} from '@/generated/graphql'
import { computed, inject, reactive, ref, watch } from 'vue'
import { v4 } from 'uuid'
import type { AttributeConfiguration } from '@/components/input/AttributeConfigurationField.vue'
import TextField from '@/components/input/TextField.vue'
import useVuelidate from '@vuelidate/core'
import { helpers, maxLength } from '@vuelidate/validators'
import { useQuery } from '@vue/apollo-composable'
import { keyEventDuplication, keyEventStart } from '@/components/events/shared'
import { DateTime } from 'luxon'
import { debounce } from 'underscore'
import TextAreaField from '@/components/input/TextAreaField.vue'
import Checkbox from '@/components/input/Checkbox.vue'
import InlineTimePicker from '@/components/input/InlineTimePicker.vue'
import FormField from '@/components/input/FormField.vue'
import ValidationErrors from '@/components/input/ValidationErrors.vue'
import gql from 'graphql-tag'
import { toTime } from '@/app'

const props = defineProps<{
  eventId: string
  meta: AttributeMeta
  seed?: FlightAttribute
}>()
const emit = defineEmits<{
  command: [value: SetFlightAttributeOnEventCommand | UnsetAttributeCommand]
  setStartAndEnd: [start: string, startTimezone: string, end: string, endTimezone: string]
}>()

type MutationModel = {
  id?: string
  eventId?: string
  attributeId?: string
  lookupEnabled?: boolean
  carrierCode?: string
  flightNumber?: string
  operationalSuffix?: string
  departureDateTimeScheduled?: string
  departureAirportIataCode?: string
  departureTerminal?: string
  departureGate?: string
  departureDetails?: string
  arrivalDateTimeScheduled?: string
  arrivalAirportIataCode?: string
  arrivalTerminal?: string
  arrivalGate?: string
  bookingReference?: string
  baggageDetails?: string
  seatDetails?: string
  comments?: string
  pdf?: string
}
const initialState: MutationModel = {
  id: props.seed?.id || v4(),
  attributeId: props.meta.id,
  eventId: props.eventId,
  lookupEnabled: props.seed?.lookupEnabled || true,
  carrierCode: props.seed?.carrierCode,
  flightNumber: props.seed?.flightNumber,
  operationalSuffix: props.seed?.operationalSuffix,
  departureDateTimeScheduled: props.seed?.departureDateTimeScheduled,
  departureAirportIataCode: props.seed?.departureAirportIataCode,
  departureTerminal: props.seed?.departureTerminal,
  departureGate: props.seed?.departureGate,
  departureDetails: props.seed?.departureDetails,
  arrivalDateTimeScheduled: props.seed?.arrivalDateTimeScheduled,
  arrivalAirportIataCode: props.seed?.arrivalAirportIataCode,
  arrivalTerminal: props.seed?.arrivalTerminal,
  arrivalGate: props.seed?.arrivalGate,
  bookingReference: props.seed?.bookingReference,
  baggageDetails: props.seed?.baggageDetails,
  seatDetails: props.seed?.seatDetails,
  comments: props.seed?.comments,
  pdf: props.seed?.pdf
}
const mutationModel = reactive<MutationModel>({ ...initialState })
const unsetCommand: UnsetAttributeCommand = {
  eventId: props.eventId,
  attributeId: initialState.id!
}
function isComplete(model: MutationModel) {
  return (model.carrierCode?.length || 0 > 0) && (model.flightNumber?.length || 0 > 0)
}
const isDuplication = inject(keyEventDuplication)!
watch(
  mutationModel,
  () => emit('command', isComplete(mutationModel) ? mutationModel : unsetCommand),
  { immediate: isDuplication.value }
)

const airportIataCodeValidator = helpers.withMessage(
  'Needs to be 3 letters and/or numbers',
  helpers.regex(/^[a-zA-Z0-9]{3}$/)
)
const airportTerminalAndGatePatternValidator = helpers.withMessage(
  'Needs to be letters, numbers, space or -',
  helpers.regex(/^[a-zA-Z0-9 -]{0,25}$/)
)
const validation = useVuelidate<MutationModel>(
  {
    departureAirportIataCode: { pattern: airportIataCodeValidator },
    departureTerminal: { pattern: airportTerminalAndGatePatternValidator, max: maxLength(25) },
    departureGate: { pattern: airportTerminalAndGatePatternValidator, max: maxLength(10) },
    arrivalAirportIataCode: { pattern: airportIataCodeValidator },
    arrivalTerminal: { pattern: airportTerminalAndGatePatternValidator, max: maxLength(25) },
    arrivalGate: { pattern: airportTerminalAndGatePatternValidator, max: maxLength(10) },
    pdf: { max: helpers.withMessage('Tickets can be at most 150KB ', maxLength(200_000)) }
  },
  mutationModel
)

const flightSearch = ref<string>()
const debouncedFlightSearch = ref<string>()
const debouncer = debounce(() => {
  debouncedFlightSearch.value = flightSearch.value
}, 500)
watch(flightSearch, debouncer)

let flightSearchRegex = /^([a-zA-Z0-9]{2})([0-9]{2,4})$/
const flightSearchValidation = useVuelidate<string>(
  {
    pattern: helpers.withMessage(
      'Needs to be 2-3 letters and 2-4 numbers',
      helpers.regex(flightSearchRegex)
    )
  },
  debouncedFlightSearch
)
watch(debouncedFlightSearch, () => flightSearchValidation.value.$touch())
watch(debouncedFlightSearch, (v?: string) => {
  let match = v?.match(flightSearchRegex)
  if (!v || !match) {
    mutationModel.carrierCode = undefined
    mutationModel.flightNumber = undefined
    return
  }

  mutationModel.carrierCode = match[1]
  mutationModel.flightNumber = match[2]
})
watch(
  () => props.seed,
  () => {
    if (!props.seed) {
      return
    }

    flightSearch.value =
      debouncedFlightSearch.value = `${props.seed.carrierCode}${props.seed.flightNumber}`
  },
  { immediate: true }
)
const eventStart = inject(keyEventStart)!
const eventStartTooFarInPast = computed(() => {
  const oneMonthAgo = DateTime.now().startOf('day').minus({ month: 1 })
  return eventStart.value ? DateTime.fromISO(eventStart.value) < oneMonthAgo : true
})
const fetchQuery = useQuery<Query>(
  gql`
    query findFlightsForAttribute(
      $carrierCode: String!
      $flightNumber: String!
      $departureDate: LocalDate!
    ) {
      travel {
        id
        findCommercialFlights(
          carrierCode: $carrierCode
          flightNumber: $flightNumber
          departureDate: $departureDate
        ) {
          id
          carrierCode
          flightNumber
          startTimezoneId
          endTimezoneId
          details
          departureAirportIataCode
          departureAirportDescription
          departureDateTimeScheduled
          departureTerminal
          departureGate
          arrivalAirportIataCode
          arrivalAirportDescription
          arrivalDateTimeScheduled
          arrivalTerminal
          arrivalGate
        }
      }
    }
  `,
  () => ({
    departureDate: DateTime.fromISO(eventStart.value).toISODate(),
    carrierCode: mutationModel.carrierCode?.toUpperCase(),
    flightNumber: mutationModel.flightNumber
  }),
  () => ({
    enabled:
      !!mutationModel.carrierCode &&
      !!mutationModel.flightNumber &&
      mutationModel.lookupEnabled &&
      !eventStartTooFarInPast.value
  })
)
const foundFlights = computed(
  () =>
    fetchQuery.result.value?.travel.findCommercialFlights.map((f: CommercialFlight) => ({
      value: f,
      title: `${f.departureAirportIataCode}-${f.arrivalAirportIataCode} ${toTime(
        f.departureDateTimeScheduled
      )}`
    })) || []
)
const selectedFlight = ref<CommercialFlight>()
watch(foundFlights, () => (selectedFlight.value = undefined))
watch(selectedFlight, (v?: CommercialFlight) => {
  if (!v) {
    return
  }

  mutationModel.departureAirportIataCode = v.departureAirportIataCode
  mutationModel.departureTerminal = v.departureTerminal
  mutationModel.departureGate = v.departureGate
  mutationModel.departureDateTimeScheduled = v.departureDateTimeScheduled
  mutationModel.arrivalAirportIataCode = v.arrivalAirportIataCode
  mutationModel.arrivalTerminal = v.arrivalTerminal
  mutationModel.arrivalGate = v.arrivalGate
  mutationModel.arrivalDateTimeScheduled = v.arrivalDateTimeScheduled

  mutationModel.departureDetails = v.details
  emit(
    'setStartAndEnd',
    v.departureDateTimeScheduled,
    v.startTimezoneId as string,
    v.arrivalDateTimeScheduled,
    v.endTimezoneId as string
  )

  validation.value.$touch()
})

const pdf = ref<File[]>()
async function upload() {
  if (!pdf.value) {
    return
  }

  const reader = new FileReader()
  reader.onload = () => {
    const [metadata, content] = (reader.result as string).split(';')
    const [encoding, contentInBase64] = content.split(',')
    const [data, type] = metadata.split(':')

    mutationModel.pdf = contentInBase64
    pdf.value = undefined
    validation.value.pdf.$touch()
  }
  reader.readAsDataURL(pdf.value[0])
}
</script>

<template>
  <form-field :label="props.meta.name" :validation="flightSearchValidation">
    <v-row>
      <v-col cols="6">
        <v-text-field v-model="flightSearch" hide-details :loading="fetchQuery.loading.value" />
      </v-col>
      <v-col>
        <v-select
          v-if="foundFlights.length > 0"
          v-model="selectedFlight"
          :items="foundFlights"
          hide-details
          placeholder="Select flight to fill in details"
        />
      </v-col>
    </v-row>
  </form-field>
  <template v-if="!flightSearchValidation.$error && !!debouncedFlightSearch">
    <checkbox :label="`${props.meta.name} lookup enabled`" v-model="mutationModel.lookupEnabled" />

    <v-alert type="info" v-if="mutationModel.lookupEnabled && eventStartTooFarInPast">
      Flights in the past cannot be looked up
    </v-alert>

    <label>Departing from</label>
    <v-row>
      <v-col cols="3">
        <text-field
          label="IATA code airport"
          v-model="mutationModel.departureAirportIataCode"
          :validation="validation.departureAirportIataCode"
          @blur="
            mutationModel.departureAirportIataCode =
              mutationModel.departureAirportIataCode?.toUpperCase()
          "
        />
      </v-col>
      <v-col cols="3">
        <text-field
          label="Terminal"
          v-model="mutationModel.departureTerminal"
          :validation="validation.departureTerminal"
        />
      </v-col>
      <v-col cols="3">
        <text-field
          label="Gate"
          v-model="mutationModel.departureGate"
          :validation="validation.departureGate"
        />
      </v-col>
      <v-col>
        <form-field label="Time" :validation="validation.departureDateTimeScheduled">
          <inline-time-picker v-model="mutationModel.departureDateTimeScheduled" />
        </form-field>
      </v-col>
    </v-row>

    <label>Arriving at</label>
    <v-row>
      <v-col cols="3">
        <text-field
          label="IATA code airport"
          v-model="mutationModel.arrivalAirportIataCode"
          :validation="validation.arrivalAirportIataCode"
          @blur="
            mutationModel.arrivalAirportIataCode =
              mutationModel.arrivalAirportIataCode?.toUpperCase()
          "
        />
      </v-col>
      <v-col cols="3">
        <text-field
          label="Terminal"
          v-model="mutationModel.arrivalTerminal"
          :validation="validation.arrivalTerminal"
        />
      </v-col>
      <v-col cols="3">
        <text-field
          label="Gate"
          v-model="mutationModel.arrivalGate"
          :validation="validation.arrivalGate"
        />
      </v-col>
      <v-col>
        <form-field label="Time" :validation="validation.arrivalDateTimeScheduled">
          <inline-time-picker v-model="mutationModel.arrivalDateTimeScheduled" />
        </form-field>
      </v-col>
    </v-row>
    <v-alert type="warning">
      The field below overlaps with the fields above and is here so you can already use this new
      Console. The field below is still visible in the current app, the fields above are not but
      will be in the new calendar app. Make sure to update both.
    </v-alert>
    <text-area-field
      :label="`${props.meta.name} departure detail`"
      v-model="mutationModel.departureDetails"
    />
    <text-field
      :label="`${props.meta.name} booking reference`"
      v-model="mutationModel.bookingReference"
    />
    <text-area-field
      :label="`${props.meta.name} bagage details`"
      v-model="mutationModel.baggageDetails"
    />
    <text-area-field
      :label="`${props.meta.name} seat details`"
      v-model="mutationModel.seatDetails"
    />
    <text-area-field :label="`${props.meta.name} comments`" v-model="mutationModel.comments" />
    <form-field label="Tickets (PDF)">
      <v-file-input
        v-if="!mutationModel.pdf"
        hide-details
        show-size
        v-model="pdf"
        accept="application/pdf"
      >
        <template v-slot:append>
          <v-btn icon="upload" @click="upload" :color="!!pdf ? 'primary' : 'white'" />
        </template>
      </v-file-input>
      <v-btn v-if="mutationModel.pdf" icon="delete" @click="mutationModel.pdf = undefined" />
      <validation-errors :validation="validation.pdf" />
    </form-field>
  </template>
</template>

<style scoped lang="scss">
label {
  font-size: 0.95em;
  font-style: italic;
}
</style>
