<script setup lang="ts">
import gql from 'graphql-tag'
import { computed, inject, ref, watch } from 'vue'
import type { IntegrationGoogleSheets, Query } from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import { keyOrganizationId, keyProjectId } from '@/app'

const props = defineProps<{
  refetch?: number
}>()
const emit = defineEmits<{
  authorized: []
}>()

const orgId = inject(keyOrganizationId)!
const projectId = inject(keyProjectId)!
const fetchQuery = useQuery<Query>(
  gql`
    query getGoogleSheetsAuthorization($orgId: ID!, $projectId: ID!) {
      organization(id: $orgId) {
        id
        project(id: $projectId) {
          id
          integrations {
            googleSheets {
              isAuthorized
              authorizationUrl
            }
          }
        }
      }
    }
  `,
  () => ({
    orgId: orgId.value,
    projectId: projectId.value
  })
)
watch(
  () => props.refetch,
  () => fetchQuery.refetch()
)
const authorization = computed<IntegrationGoogleSheets | undefined>(
  () => fetchQuery.result.value?.organization.project.integrations.googleSheets || undefined
)
const notification = ref(false)
watch(
  authorization,
  async (v: IntegrationGoogleSheets | undefined) => {
    if (v === undefined) {
      return
    }

    if (!v.isAuthorized) {
      window.open(v.authorizationUrl!, 'oauth-sheets')
      return
    }

    notification.value = true
    emit('authorized')
  },
  { immediate: true }
)

const channel = new BroadcastChannel('oauth')
channel.onmessage = async () => fetchQuery.refetch()
</script>

<template>
  <v-snackbar :timeout="2000" v-model="notification">Authenticated with Google Sheets</v-snackbar>
</template>
