<script setup lang="ts">
import GoogleSheetsExport from '@/components/integrations/google-sheets/GoogleSheetsExport.vue'
import { ref } from 'vue'
import DeleteMultiEvents from '@/components/events/DeleteMultiEvents.vue'

const props = defineProps<{
  modelValue: string[]
}>()
const emit = defineEmits<{
  'update:modelValue': [value: string[]]
  refresh: []
}>()

const googleSheetItems = ref<string[]>([])
const googleSheetUrl = ref<string>()
const googleSheetLoading = ref(false)
const deleteOpen = ref(false)
</script>

<template>
  <v-navigation-drawer
    v-if="props.modelValue.length > 0"
    expand-on-hover
    location="right"
    permanent
    rail
    width="300"
  >
    <v-list>
      <v-list-item>
        <template #prepend>
          <v-chip>{{ props.modelValue.length }}</v-chip>
        </template>
      </v-list-item>
      <v-list-item
        prepend-icon="delete"
        :title="`Delete ${props.modelValue.length} events`"
        @click="deleteOpen = true"
      />
      <v-list-item
        prepend-icon="ios_share"
        title="Export to Google sheets"
        @click="googleSheetItems = props.modelValue.slice()"
      >
        <template v-if="googleSheetLoading" #append>
          <v-progress-circular indeterminate size="small" />
        </template>
        <template v-if="!!googleSheetUrl" #subtitle>
          <a :href="googleSheetUrl" target="_blank" rel="noopener">Last export</a>
        </template>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

  <google-sheets-export
    v-model="googleSheetItems"
    @url="(v) => (googleSheetUrl = v)"
    @loading="(v) => (googleSheetLoading = v)"
  />
  <delete-multi-events
    v-model="deleteOpen"
    :ids="props.modelValue"
    @deleted="
      () => {
        emit('update:modelValue', [])
        emit('refresh')
      }
    "
  />
</template>

<style scoped lang="scss"></style>
