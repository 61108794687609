<script setup lang="ts">
import ScopePicker from '@/components/layout/ScopePicker.vue'
import { inject } from 'vue'
import { keyOrganizationId, keyProjectId } from '@/app'
import { useRouter } from 'vue-router'
import NotificationCenter from '@/components/notifications/NotificationCenter.vue'

defineEmits<{
  (e: 'toggleDrawer'): void
}>()
const orgId = inject(keyOrganizationId)
const projectId = inject(keyProjectId)
const router = useRouter()
</script>

<template>
  <v-app-bar class="main-app-bar">
    <template v-slot:title>
      <router-link class="main-title" :to="{ name: 'home' }">Calena</router-link>
    </template>
    <template v-slot:prepend>
      <v-app-bar-nav-icon @click="$emit('toggleDrawer')"></v-app-bar-nav-icon>
    </template>
    <template v-slot:append>
      <template v-if="orgId && projectId">
        <router-link :to="{ name: 'resources' }"> <v-icon icon="person_outline" /> </router-link>
        <router-link :to="{ name: 'calendar' }"><v-icon icon="calendar_month" /></router-link>
      </template>
      <scope-picker class="scope-picker" />
      <notification-center class="notification-center" />
    </template>
  </v-app-bar>
</template>

<style lang="scss">
.v-app-bar.v-toolbar.main-app-bar {
  background: linear-gradient(
    172deg,
    rgb(var(--v-theme-primary-darken-1)) 30%,
    rgb(var(--v-theme-primary)) 100%
  );

  .scope-picker,
  .notification-center {
    color: #fff;
    margin-left: 1em;
  }
  .v-app-bar-nav-icon,
  .main-title,
  a {
    color: darken(#fff, 10%);
  }
  a {
    margin-left: 0.5em;
  }
  a:first-of-type {
    margin-left: 0;
  }
  a:hover {
    text-decoration: none;
  }
}
</style>
