<script setup lang="ts">
import { ref, watch } from 'vue'
import { DateTime } from 'luxon'
import { luxonToIsoOptions } from '@/app'

const props = defineProps<{
  modelValue?: string
}>()
const emit = defineEmits<{
  'update:modelValue': [value?: string]
}>()

const pickerValue = ref<Date>()
watch(
  () => props.modelValue,
  (v?: string) => {
    if (!v) {
      return
    }
    pickerValue.value = DateTime.fromISO(v).toJSDate()
  },
  { immediate: true }
)
watch(pickerValue, (v?: Date) => {
  let isoString = v ? DateTime.fromJSDate(v).toISO(luxonToIsoOptions) : undefined
  emit('update:modelValue', isoString)
})

const masks = {
  input: [
    'D MMM',
    'D-M',
    'DD-MM',
    'D MMM yy',
    'D MMM yyyy',
    'D-M-y',
    'D-M-yyyy',
    'DD-MM-yy',
    'DD-MM-yyyy'
  ]
}
</script>

<template>
  <v-date-picker
    mode="date"
    v-model="pickerValue"
    borderless
    color="pink"
    :masks="masks"
    :update-on-input="false"
    class="date-picker"
    :popover="{ visibility: 'focus' }"
  >
    <template #default="{ inputValue, inputEvents }">
      <v-text-field :model-value="inputValue" v-on="inputEvents" hide-details />
    </template>
  </v-date-picker>
</template>

<style lang="scss"></style>
