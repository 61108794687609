<script setup lang="ts" generic="MM">
import { computed, ref, watch } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import type { Query, Timezone, TimezonePlace } from '@/generated/graphql'
import { Validation, ValidationArgs } from '@vuelidate/core'
import EditDialog from '@/components/dialogs/EditDialog.vue'
import { debounce } from 'underscore'

const props = defineProps<{
  modelValue?: string
  validation: Validation<ValidationArgs, MM>
}>()
const emit = defineEmits<{
  'update:modelValue': [value: string]
}>()

const fetchQuery = useQuery<Query>(
  gql`
    query getTimezones {
      findTimezoneById(partial: "") {
        id
      }
    }
  `,
  {},
  {
    fetchPolicy: 'cache-first'
  }
)

const timezones = computed(
  () =>
    fetchQuery.result.value?.findTimezoneById.map((v: Timezone) => ({
      title: v.id,
      value: v.id
    })) || []
)
const selectedTimezone = ref<string>()
watch(
  () => props.modelValue,
  () => {
    selectedTimezone.value = props.modelValue
  },
  { immediate: true }
)
watch(selectedTimezone, () => {
  emit('update:modelValue', selectedTimezone.value)
})

const searchOpen = ref(false)
const selectedSearchTimezone = ref<string>()
const searchString = ref<string>()
const debouncedSearchString = ref<string>()
const debouncer = debounce(() => {
  debouncedSearchString.value = searchString.value
}, 500)
watch(searchString, debouncer)

const searchQuery = useQuery<Query>(
  gql`
    query findTimezoneByPlace($partial: String!) {
      findTimezoneByPlace(partial: $partial) {
        name
        description
        timezone {
          id
        }
      }
    }
  `,
  () => ({
    partial: debouncedSearchString.value
  }),
  () => ({
    enabled: !!debouncedSearchString.value && debouncedSearchString.value?.length >= 3
  })
)
const foundTimezones = computed(
  () =>
    searchQuery.result.value?.findTimezoneByPlace.map((t: TimezonePlace) => ({
      title: `${t.name} - ${t.description} (${t.timezone.id})`,
      value: t.timezone.id
    })) || []
)
function closeSearch() {
  selectedSearchTimezone.value = undefined
  searchOpen.value = false
}
function acceptSearchSelection() {
  selectedTimezone.value = selectedSearchTimezone.value
  closeSearch()
}
</script>

<template>
  <v-autocomplete
    v-model="selectedTimezone"
    :items="timezones"
    :loading="fetchQuery.loading.value"
    hide-details
    auto-select-first
    @blur="props.validation.$touch()"
  >
    <template #append-inner>
      <v-icon icon="search" @click="searchOpen = true" />
    </template>
  </v-autocomplete>

  <edit-dialog v-model="searchOpen" @save="acceptSearchSelection()" @cancel="closeSearch()">
    <template #title>Search timezone</template>
    <template #default>
      <v-autocomplete
        auto-select-first
        autofocus
        hide-details
        hide-no-data
        no-filter
        variant="solo"
        v-model="selectedSearchTimezone"
        v-model:search="searchString"
        :items="foundTimezones"
        :loading="searchQuery.loading.value"
      />
    </template>
  </edit-dialog>
</template>

<style scoped lang="scss"></style>
