<script setup lang="ts">
import type {
  AttributeMeta,
  LinkAttribute,
  LinkAttributeTarget,
  SetLinkAttributesOnEventCommand,
  UnsetAttributeCommand
} from '@/generated/graphql'
import { inject, reactive, watch } from 'vue'
import type { AttributeConfiguration } from '@/components/input/AttributeConfigurationField.vue'
import { maxLength, required } from '@vuelidate/validators'
import TextField from '@/components/input/TextField.vue'
import FormField from '@/components/input/FormField.vue'
import { ValidateEach } from '@vuelidate/components'
import { keyEventDuplication } from '@/components/events/shared'

const props = defineProps<{
  eventId: string
  meta: AttributeMeta
  seed?: LinkAttribute
}>()
const emit = defineEmits<{
  command: [value: SetLinkAttributesOnEventCommand | UnsetAttributeCommand]
}>()

type MutationModel = {
  attributeId: string
  eventId: string
  targets: TargetMutationModel[]
}
type TargetMutationModel = {
  name?: string
  target?: string
}
const initialState: MutationModel = {
  attributeId: props.meta.id,
  eventId: props.eventId,
  targets: []
}
const mutationModel = reactive<MutationModel>({ ...initialState })
watch(
  () => props.seed,
  () => {
    if (!props.seed?.targets) {
      return
    }

    mutationModel.targets = props.seed.targets.map((f: LinkAttributeTarget) => ({
      name: f.name,
      target: f.target
    }))
  },
  { immediate: true }
)
const isDuplication = inject(keyEventDuplication)!
watch(mutationModel, () => emit('command', mutationModel), { immediate: isDuplication.value })

const targetRules = {
  name: { required, max: maxLength(255) },
  target: { required, max: maxLength(2000) }
}
function addTarget() {
  mutationModel.targets.push({
    name: undefined,
    target: undefined
  })
}
</script>

<template>
  <form-field :label="meta.name">
    <validate-each v-for="t in mutationModel.targets" :state="t" :rules="targetRules" :key="t">
      <template #default="{ v }">
        <v-row dense="">
          <v-col cols="5">
            <text-field label="Name" v-model="t.name" :validation="v.name" required />
          </v-col>
          <v-col cols="7">
            <text-field label="Target" v-model="t.target" :validation="v.target" required>
              <template v-slot:append>
                <v-btn
                  size="small"
                  icon="delete"
                  @click="mutationModel.targets = mutationModel.targets.filter((mmt) => mmt !== t)"
                />
              </template>
            </text-field>
          </v-col>
        </v-row>
      </template>
    </validate-each>
    <v-btn icon="add_link" @click="addTarget" size="small" variant="elevated" />
  </form-field>
</template>

<style scoped lang="scss"></style>
