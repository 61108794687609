<script setup lang="ts">
import { computed, inject, reactive, ref } from 'vue'
import { authzCanWrite, itemSort, keyOrganizationId, keyProjectId } from '@/app'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import type { Query, Resource } from '@/generated/graphql'
import useVuelidate from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import TextField from '@/components/input/TextField.vue'
import ItemTable from '@/components/items/ItemTable.vue'
import ItemPage from '@/components/items/ItemPage.vue'
import TextAreaField from '@/components/input/TextAreaField.vue'
import ItemDeleteIcon from '@/components/items/ItemDeleteIcon.vue'
import ItemEditIcon from '@/components/items/ItemEditIcon.vue'

const orgId = inject(keyOrganizationId)!
const projectId = inject(keyProjectId)!
const fetchQuery = useQuery<Query>(
  gql`
    query getResources($orgId: ID!, $projectId: ID!) {
      organization(id: $orgId) {
        id
        project(id: $projectId) {
          id
          resources {
            id
            name
            description
          }
        }
      }
    }
  `,
  () => ({
    orgId: orgId.value,
    projectId: projectId.value
  })
)
const items = computed(() =>
  itemSort<Resource>(fetchQuery.result.value?.organization.project.resources, (i) => i.name)
)

type MutationModel = {
  id?: string
  name?: string
  description?: string
}
const initialState = {
  id: undefined,
  name: undefined,
  description: undefined
}
const mutationModel = reactive<MutationModel>({ ...initialState })
function resetMutationModel() {
  Object.assign(mutationModel, initialState)
}
const validation = useVuelidate<MutationModel>(
  {
    name: { required, min: minLength(3) }
  },
  mutationModel
)

const createMutation = useMutation(
  gql`
    mutation createResource($orgId: ID!, $projectId: ID!, $command: CreateResourceCommand!) {
      organization(id: $orgId) {
        project(id: $projectId) {
          resources {
            createResource(input: $command) {
              id
            }
          }
        }
      }
    }
  `,
  () => ({
    refetchQueries: ['getResourcesForAttribute']
  })
)
async function create() {
  return createMutation.mutate({
    command: mutationModel,
    orgId: orgId.value,
    projectId: projectId.value
  })
}

const updateOpen = ref(false)
function updateItem(item: Resource) {
  Object.keys(initialState).forEach((k: string) => {
    mutationModel[k] = item[k]
  })
  updateOpen.value = true
}
const updateMutation = useMutation(
  gql`
    mutation updateResource($orgId: ID!, $projectId: ID!, $command: UpdateResourceCommand!) {
      organization(id: $orgId) {
        project(id: $projectId) {
          resources {
            updateResource(input: $command) {
              id
            }
          }
        }
      }
    }
  `,
  () => ({
    refetchQueries: ['getResourcesForAttribute']
  })
)

async function update() {
  return updateMutation.mutate({
    command: mutationModel,
    orgId: orgId.value,
    projectId: projectId.value
  })
}

const deleteItem = ref<Resource>()
const deleteMutation = useMutation(
  gql`
    mutation deleteResource($orgId: ID!, $projectId: ID!, $id: ID!) {
      organization(id: $orgId) {
        project(id: $projectId) {
          resources {
            deleteResource(input: { id: $id }) {
              id
            }
          }
        }
      }
    }
  `,
  () => ({
    refetchQueries: ['getResourcesForAttribute']
  })
)
function doDelete(id: string) {
  return deleteMutation.mutate({ id, orgId: orgId.value, projectId: projectId.value })
}
</script>

<template>
  <item-page
    title="Resources"
    :fetch="fetchQuery.refetch"
    :create-validation="validation"
    :create="create"
    @reset-mutation-model="resetMutationModel()"
    :update-validation="validation"
    :update="update"
    v-model:update-open="updateOpen"
    :delete="doDelete"
    v-model:delete-item="deleteItem"
    delete-warning="If you delete a resource, it will be decoupled from all events (but the events will stay). This is permanent and not undoable."
  >
    <template #items-table>
      <item-table :headers="['Name']" :items="items">
        <template #item="{ item }">
          <td>{{ item.name }}</td>
          <td>
            <item-edit-icon v-if="authzCanWrite" @click="updateItem(item)" />
            <item-delete-icon v-if="authzCanWrite" @click="deleteItem = item" />
          </td>
        </template>
      </item-table>
    </template>
    <template #create-form>
      <text-field
        label="Name"
        required
        v-model="mutationModel.name"
        :validation="validation.name"
      />
      <text-area-field
        label="Description"
        v-model="mutationModel.description"
        :validation="validation.description"
      />
    </template>
    <template #update-form>
      <text-field
        label="Name"
        required
        v-model="mutationModel.name"
        :validation="validation.name"
      />
      <text-area-field
        label="Description"
        v-model="mutationModel.description"
        :validation="validation.description"
      />
    </template>
  </item-page>
</template>

<style scoped lang="scss"></style>
