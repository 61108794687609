<script setup lang="ts">
import gql from 'graphql-tag'
import { computed, ref, watch } from 'vue'
import type { Organization, Query } from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import type { SelectItem } from '@/vuetify'
import { useRoute, useRouter } from 'vue-router'
import { itemSort } from '@/app'

type Scope = ProjectScope | OrganizationScope
type ProjectScope = {
  type: 'project'
  organizationId: string
  projectId: string
}
type OrganizationScope = {
  type: 'organization'
  organizationId: string
}

const fetchQuery = useQuery<Query>(gql`
  query scopePickerList {
    organizations {
      id
      name
      projects {
        id
        name
      }
    }
  }
`)
const items = computed(() => {
  const orgs = fetchQuery.result.value?.organizations
  if (!orgs) {
    return undefined
  }

  return itemSort(orgs, (i) => i.name).flatMap(mapOrganizationToItems) || []
})
function mapOrganizationToItems(organization: Organization): SelectItem[] {
  const orgItems: SelectItem[] = [
    {
      props: {
        isOrg: true
      },
      title: organization.name,
      value: { type: 'organization', organizationId: organization.id }
    }
  ]
  const projects = itemSort(organization.projects, (i) => i.name).map((project) => ({
    props: {
      isOrg: false
    },
    title: project.name,
    value: { type: 'project', organizationId: organization.id, projectId: project.id }
  }))
  orgItems.push(...projects)

  return orgItems
}

const selected = ref<Scope | undefined>(undefined)

// Adjust route to selection
const router = useRouter()
watch(
  () => selected.value,
  (value: Scope | undefined) => {
    if (!value) {
      return
    }
    // Blur so it does not react to keyboard shortcuts in the view
    document.activeElement?.blur && document.activeElement.blur()

    if (
      value.type == 'organization' &&
      (value.organizationId != route.params.organizationId || route.params.projectId)
    ) {
      router.push({
        name: 'organization-home',
        params: { organizationId: value.organizationId }
      })
      return
    }
    if (value.type == 'project' && value.projectId != route.params.projectId) {
      router.push({
        name: 'calendar',
        params: { organizationId: value.organizationId, projectId: value.projectId }
      })
    }
  },
  { immediate: true }
)

// Adjust selection to route
const route = useRoute()
const organizationId = computed(() => route.params.organizationId as string | undefined)
const projectId = computed(() => route.params.projectId as string | undefined)
watch(
  () => [projectId.value, organizationId.value],
  () => {
    if (projectId.value && organizationId.value) {
      selected.value = {
        type: 'project',
        organizationId: organizationId.value as string,
        projectId: projectId.value as string
      }
    } else if (organizationId.value) {
      selected.value = { type: 'organization', organizationId: organizationId.value as string }
    }
  },
  { immediate: true }
)
</script>

<template>
  <v-select
    flat
    outlined
    hide-details
    class="project-picker"
    placeholder="Select a project"
    v-model="selected"
    :items="items"
  >
    <template #item="{ item, props }">
      <v-divider v-if="item.props.isOrg" />
      <v-list-item v-bind="props" :value="item.value">
        <template #title>
          <span :class="item.props.isOrg ? 'org-item' : 'project-item'">{{ item.title }}</span>
        </template>
      </v-list-item>
    </template>
  </v-select>
</template>

<style scoped lang="scss">
.project-picker {
  min-width: 200px;
}
.org-item {
  font-weight: bold;
}
</style>
