<script setup lang="ts">
import type { CommercialFlight, FlightAttribute } from '@/generated/graphql'
import { computed, ref, watch } from 'vue'

const props = defineProps<{
  flight?: CommercialFlight
  attr: FlightAttribute
}>()

type Differences = {
  departureAirport: boolean
  departureTerminal: boolean
  departureGate: boolean
  departureTime: boolean
  arrivalAirport: boolean
  arrivalTerminal: boolean
  arrivalGate: boolean
  arrivalTime: boolean
}
const differencesModel = ref<Differences>()
const anyChanges = computed(() => {
  if (!differencesModel.value) {
    return false
  }

  return Object.keys(differencesModel.value)
    .map((k: string) => differencesModel.value[k])
    .reduce((p: boolean, c: boolean) => p || c)
})

function differs(key: string) {
  if (!props.attr || !props.flight) {
    return false
  }

  const attrValue = props.attr[key]
  const flightValue = props.flight[key]
  if ((!attrValue && !!flightValue) || (!!attrValue && !flightValue)) {
    return true
  }
  if (!attrValue && !flightValue) {
    return false
  }

  return (attrValue as string).toUpperCase() != (flightValue as string).toUpperCase()
}
watch(
  () => [props.attr, props.flight],
  () => {
    differencesModel.value = {
      departureAirport: differs('departureAirportIataCode'),
      departureTerminal: differs('departureTerminal'),
      departureGate: differs('departureGate'),
      departureTime: differs('departureDateTimeScheduled'),
      arrivalAirport: differs('arrivalAirportIataCode'),
      arrivalTerminal: differs('arrivalTerminal'),
      arrivalGate: differs('arrivalGate'),
      arrivalTime: differs('arrivalDateTimeScheduled')
    }
  },
  { immediate: true, deep: true }
)
</script>

<template>
  <v-alert v-if="anyChanges" type="warning" icon="change_circle">
    <template #append>
      <slot name="append" />
    </template>

    <strong>Changes between live result and saved values:</strong>
    <ul>
      <li v-if="differencesModel?.departureAirport">Departure airport</li>
      <li v-if="differencesModel?.departureTerminal">Departure terminal</li>
      <li v-if="differencesModel?.departureGate">Departure gate</li>
      <li v-if="differencesModel?.departureTime">Departure time</li>
      <li v-if="differencesModel?.arrivalAirport">Arrival airport</li>
      <li v-if="differencesModel?.arrivalTerminal">Arrival terminal</li>
      <li v-if="differencesModel?.arrivalGate">Arrival gate</li>
      <li v-if="differencesModel?.arrivalTime">Arrival time</li>
    </ul>
  </v-alert>
</template>

<style scoped lang="scss"></style>
