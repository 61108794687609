<script setup lang="ts" generic="MM">
import { computed, ref, watch } from 'vue'
import FormField from '@/components/input/FormField.vue'
import InlineDatePicker from '@/components/input/InlineDatePicker.vue'
import { DateTime } from 'luxon'
import InlineTimePicker from '@/components/input/InlineTimePicker.vue'
import InlineTimezonePicker from '@/components/input/InlineTimezonePicker.vue'
import { Validation, ValidationArgs } from '@vuelidate/core'
import { luxonToIsoOptions } from '@/app'
import { useQuery } from '@vue/apollo-composable'
import type { DstTransitionDates, Query } from '@/generated/graphql'
import gql from 'graphql-tag'

const props = defineProps<{
  start?: string
  startValidation: Validation<ValidationArgs, MM>
  startTimezone?: string
  startTimezoneValidation: Validation<ValidationArgs, MM>
  end?: string
  endValidation: Validation<ValidationArgs, MM>
  endTimezone?: string
  endTimezoneValidation: Validation<ValidationArgs, MM>
}>()
const emit = defineEmits<{
  'update:start': [value: string]
  'update:startTimezone': [value: string]
  'update:end': [value: string]
  'update:endTimezone': [value: string]
}>()

const start = ref<string | undefined>(props.start)
watch(start, (v: string) => emit('update:start', v))
watch(
  () => props.start,
  () => (start.value = props.start)
)

const end = ref<string | undefined>(props.end)
watch(end, (v: string) => emit('update:end', v))
watch(
  () => props.end,
  () => (end.value = props.end)
)

watch(start, (nVal?: string, oVal?: string) => {
  if (!nVal) {
    return
  }

  const nValLuxon = DateTime.fromISO(nVal)
  if (!end.value) {
    end.value = nValLuxon.plus({ hour: 1 }).toISO(luxonToIsoOptions)
    return
  }
  if (!oVal) {
    return
  }

  const diff = nValLuxon.diff(DateTime.fromISO(oVal))
  end.value = DateTime.fromISO(end.value).plus(diff).toISO(luxonToIsoOptions)
})
watch(
  () => props.startTimezone,
  () => {
    emit('update:endTimezone', props.startTimezone)
  }
)

const dstTransitionFetchQuery = useQuery<Query>(gql`
  query getDstTransitionDays {
    dstTransitionDates {
      timezone {
        id
      }
      dates
    }
  }
`)
const dstTransitions = computed<DstTransitionDates[]>(
  () => dstTransitionFetchQuery.result.value?.dstTransitionDates || []
)
function isDstTransitionDate(dateTime?: string, timezone?: string) {
  if (!dateTime || !timezone) {
    return false
  }

  const date = DateTime.fromISO(dateTime).toISODate()
  return !!dstTransitions.value
    ?.find((t: DstTransitionDates) => t.timezone.id == timezone)
    ?.dates?.find((d: string) => d == date)
}
const startIsDstTransitionDate = computed(() =>
  isDstTransitionDate(props.start, props.startTimezone)
)
const endIsDstTransitionDate = computed(() => isDstTransitionDate(props.end, props.endTimezone))
</script>

<template>
  <form-field
    label="Start"
    required
    :validation="[props.startValidation, props.startTimezoneValidation]"
  >
    <v-row>
      <v-col cols="3"><inline-date-picker v-model="start" /></v-col>
      <v-col cols="3">
        <inline-time-picker v-model="start" :validation="props.startValidation" />
      </v-col>
      <v-col>
        <inline-timezone-picker
          :model-value="props.startTimezone"
          @update:modelValue="(v) => emit('update:startTimezone', v)"
          :validation="props.startTimezoneValidation"
        />
      </v-col>
    </v-row>
    <v-alert v-if="startIsDstTransitionDate" type="info" density="compact" class="mt-2">
      On this date there's a daylight savings time transition.
    </v-alert>
  </form-field>
  <form-field label="End" required :validation="[props.endValidation, props.endTimezoneValidation]">
    <v-row>
      <v-col cols="3"><inline-date-picker v-model="end" /></v-col>
      <v-col cols="3">
        <inline-time-picker v-model="end" :validation="props.endValidation" />
      </v-col>
      <v-col>
        <inline-timezone-picker
          :model-value="props.endTimezone"
          @update:modelValue="(v) => emit('update:endTimezone', v)"
          :validation="props.endTimezoneValidation"
        />
      </v-col>
    </v-row>
    <v-alert v-if="endIsDstTransitionDate" type="info" density="compact" class="mt-2">
      On this date there's a daylight savings time transition.
    </v-alert>
  </form-field>
</template>

<style lang="scss"></style>
