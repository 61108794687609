<script lang="ts" setup>
import { computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import type { Organization, Project, Query } from '@/generated/graphql'
import gql from 'graphql-tag'
import { useRouter } from 'vue-router'
import { itemSort } from '@/app'

const fetchQuery = useQuery<Query>(
  gql`
    query getHomeStats {
      organizations {
        id
        name
        projects {
          id
          name
        }
      }
    }
  `
)
const organizations = computed<Organization[]>(() =>
  itemSort(fetchQuery.result.value?.organizations || [], (o: Organization) => o.name).map(
    (o: Organization) => ({
      ...o,
      projects: itemSort(o.projects, (p: Project) => p.name)
    })
  )
)
const router = useRouter()
</script>

<template>
  <h1>Welcome</h1>

  <v-row>
    <v-col
      sm="6"
      md="3"
      xl="2"
      xxl="1"
      v-for="o in organizations"
      :key="o.id"
      class="organization-overview"
    >
      <v-card>
        <v-card-title>{{ o.name }}</v-card-title>
        <v-card-text>
          <v-row v-for="p in o.projects" :key="p.id" dense class="projects">
            <v-col cols="10">{{ p.name }}</v-col>
            <v-col>
              <v-icon
                color="primary"
                title="Go to calendar"
                icon="calendar_month"
                @click="
                  router.push({
                    name: 'calendar',
                    params: { organizationId: o.id, projectId: p.id }
                  })
                "
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-icon
            icon="login"
            title="Go to organization"
            color="primary"
            @click="router.push({ name: 'organization-home', params: { organizationId: o.id } })"
          ></v-icon>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<style lang="scss">
.organization-project-overview {
  .projects {
    font-size: 0.9em;
  }
}
</style>
