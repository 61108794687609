import type { ThemeDefinition } from 'vuetify'

export const customTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#fff',
    surface: '#fff',
    primary: '#ff0592',
    'primary-darken-1': '#250036',
    secondary: '#6dbfff',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00'
  }
}
