import type { ComputedRef } from 'vue'
import { computed } from 'vue'
import type { AttributeConfiguration } from '@/components/input/AttributeConfigurationField.vue'

export function parseConfig(rawConfig?: string): ComputedRef<AttributeConfiguration> {
  return computed<AttributeConfiguration>(() => JSON.parse(rawConfig || '{}'))
}

export function downloadFile(name: string, content: string, type: string) {
    const data = Uint8Array.from(atob(content), (c) => c.charCodeAt(0))
    const blob = new Blob([data], { type: type })
    const link = document.createElement('a')
    link.download = name
    link.href = window.URL.createObjectURL(blob)
    link.target = "_blank"
    link.click()
    URL.revokeObjectURL(link.href)
    link.remove()
}
