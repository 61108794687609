<script setup lang="ts" generic="MM">
import { ref, watch } from 'vue'
import { DateTime } from 'luxon'
import { Validation, ValidationArgs } from '@vuelidate/core'
import { luxonToIsoOptions } from '@/app'

const props = defineProps<{
  modelValue?: string
  validation: Validation<ValidationArgs, MM>
  label?: string
  density?: string
}>()
const emit = defineEmits<{
  'update:modelValue': [value: string]
}>()

const pattern = /(?<hour>\d{1,2}):?(?<minute>\d{2})/
const pickerValue = ref<string>()
function setPickerValue(v?: string) {
  pickerValue.value = v ? DateTime.fromISO(v).toFormat('H:mm') : undefined
}
watch(
  () => props.modelValue,
  (v?: string) => setPickerValue(v),
  { immediate: true }
)
function update() {
  const v = pickerValue.value
  if (!v || !v.match(pattern)) {
    setPickerValue(props.modelValue)
    return
  }

  const timeVars = v.match(pattern)!.groups!
  const hour = timeVars.hour! as unknown as number
  const minute = timeVars.minute! as unknown as number
  if (hour < 0 || hour > 24 || minute < 0 || minute > 59) {
    setPickerValue(props.modelValue)
    return
  }

  const baseDate = DateTime.fromISO(props.modelValue)
  emit(
    'update:modelValue',
    baseDate
      .set({
        hour: hour as unknown as number,
        minute: minute as unknown as number
      })
      .toISO(luxonToIsoOptions)
  )
}
</script>

<template>
  <v-text-field
    :density="props.density || 'default'"
    v-model="pickerValue"
    @blur="update()"
    hide-details
    :label="props.label"
  />
</template>

<style scoped lang="scss"></style>
