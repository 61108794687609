<script setup lang="ts">
import { IntegrationIcalImportUrl, IntegrationIcalImportUrlRun, Query } from '@/generated/graphql'
import { computed, inject, ref, watch } from 'vue'
import { keyOrganizationId, keyProjectId, toDateTime } from '@/app'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import SelectField from '@/components/input/SelectField.vue'

const props = defineProps<{
  modelValue: IntegrationIcalImportUrl
}>()
const emit = defineEmits<{
  'update:modelValue': [value?: IntegrationIcalImportUrl]
}>()

const orgId = inject(keyOrganizationId)!
const projectId = inject(keyProjectId)!
const fetchQuery = useQuery<Query>(
  gql`
    query getIcalImportUrlRun($orgId: ID!, $projectId: ID!, $id: ID!) {
      organization(id: $orgId) {
        project(id: $projectId) {
          integrations {
            icalImport {
              url(id: $id) {
                id
                runs {
                  id
                  createdAt
                  messages
                }
              }
            }
          }
        }
      }
    }
  `,
  () => ({
    orgId: orgId.value,
    projectId: projectId.value,
    id: props.modelValue.id
  })
)
const runs = computed<IntegrationIcalImportUrlRun[]>(
  () => fetchQuery.result.value?.organization.project.integrations.icalImport.url.runs || []
)
const runOptions = computed(() =>
  runs.value.map((r: IntegrationIcalImportUrlRun) => ({
    title: toDateTime(r.createdAt),
    value: r
  }))
)
const selectedRun = ref<IntegrationIcalImportUrlRun>()

const isOpen = ref(true)
watch(
  () => isOpen,
  (v: boolean) => {
    if (!v) {
      emit('update:modelValue', undefined)
    }
  }
)
</script>

<template>
  <v-dialog v-model="isOpen" max-width="750">
    <v-card>
      <v-card-title>All runs for {{ props.modelValue.name }}</v-card-title>
      <v-card-text>
        <select-field :options="runOptions" v-model="selectedRun" label="Select run" />

        <v-textarea
          :model-value="selectedRun?.messages"
          variant="outlined"
          hide-details
          rows="25"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
